import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter",
    id: "WB5-2024-U1-P8-E1",
    audio: "Audios/01-audio.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page9/Key/E1answerKey.png",
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and check (✓) the box. <span style='color: white'><headphone name='&nbsp 01' typeimg=sound src='Audios/01-tieude.mp3'></headphone></span>",
        color: "#2d408e",
        left: -33,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        isHiddenCheck: true,
        initialWordStyle: {
          padding: "1px 8px",
          border: "2px solid",
          borderRadius: "5px",
          color: "transparent",
          borderColor: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          color: "gray",
        },
        limitSelect: 1,
        listWords: [
          "✓ <br> ✓ <br> ✓",
          "✓ <br> ✓ <br> ✓",
          "✓ <br> ✓ <br> ✓",
          "✓ <br> ✓ <br> ✓",
        ],
        answers: ["2-8", "0-8", "1-8", "3-0"],
        initialValue: [],
      },
      Layout: `
      
      <div style='display:flex; gap:50px; margin-top: 10px; font-size: 20px; margin-bottom: 30px'>
        <div style='display:flex; gap: 10px; flex-direction: column'>
          <span><b>1. </b>What does Jane do in the morning?</span>
          <div style='display:flex; gap: 10px'>
            <div style='display:flex; gap: 10px; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E1/0.jpg'/>
            </div>
            <div style='display:flex; gap: 10px; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E1/1.jpg'/>
            </div>
            <div style='display:flex; gap: 10px; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E1/2.jpg'/>
            </div>
          </div>
          <div style='padding: 0 5%'>
            <div style='width:85%;display:flex;justify-content:space-between'>
              <span>(A) </span>
              <span>(B) </span>
              <span>(C) </span>
            </div>
            <div style='margin-left:15px;margin-top:-30px;display:flex;justify-content:space-around'>
                <input id=0 type=Circle />
            </div>
          </div>
        </div>
        
        <div style='display:flex; gap: 10px; flex-direction: column'>
          <span><b>2. </b> What does Jane do next?</span>
          <div style='display:flex; gap: 10px'>
            <div style='display:flex; gap: 10px; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E1/2.jpg'/>
            </div>
            <div style='display:flex; gap: 10px; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E1/1.jpg'/>
            </div>
            <div style='display:flex; gap: 10px; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E1/3.jpg'/>
            </div>
          </div>
          <div style='padding: 0 5%'>
            <div style='width:85%;display:flex;justify-content:space-between'>
              <span>(A) </span>
              <span>(B) </span>
              <span>(C) </span>
            </div>
            <div style='margin-left:15px;margin-top:-30px;display:flex;justify-content:space-around'>
                <input id=1 type=Circle />
            </div>
          </div>
        </div>
      </div>
      <div style='display:flex; gap:50px; margin-top: 10px; font-size: 20px'>  
        <div style='display:flex; gap: 10px; flex-direction: column'>
          <span><b>3. </b>What does Jane do at 11 a.m.?</span>
          <div style='display:flex; gap: 10px'>
            <div style='display:flex; gap: 10px; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E1/6.jpg'/>
            </div>
            <div style='display:flex; gap: 10px; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E1/0.jpg'/>
            </div>
            <div style='display:flex; gap: 10px; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E1/5.jpg'/>
            </div>
          </div>
          <div style='padding: 0 5%'>
            <div style='width:85%;display:flex;justify-content:space-between'>
              <span>(A) </span>
              <span>(B) </span>
              <span>(C) </span>
            </div>
            <div style='margin-left:15px;margin-top:-30px;display:flex;justify-content:space-around'>
                <input id=2 type=Circle />
            </div>
          </div>
        </div>
        
        <div style='display:flex; gap: 10px; flex-direction: column'>
          <span><b>4. </b>What does Jane do after school?</span>
          <div style='display:flex; gap: 10px'>
            <div style='display:flex; gap: 10px; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E1/4.jpg'/>
            </div>
            <div style='display:flex; gap: 10px; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E1/5.jpg'/>
            </div>
            <div style='display:flex; gap: 10px; flex-direction: column; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E1/6.jpg'/>
            </div>
          </div>
          <div style='padding: 0 5%'>
            <div style='width:85%;display:flex;justify-content:space-between'>
              <span>(A) </span>
              <span>(B) </span>
              <span>(C) </span>
            </div>
            <div style='margin-left:15px;margin-top:-30px;display:flex;justify-content:space-around'>
                <input id=3 type=Circle />
            </div>
          </div>
        </div>
      </div>
      `,
    },
  },
  2: {
    unit: "Starter",
    id: "WB5-2024-U1-P8-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page8/Key/E2answerKey.png",
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: { background: "none", fontSize: 20 },
    textAlign: "center",
    inputSize: 400,
    titleQuestion: [
      {
        num: "2",
        title: " Write. <hintbox id=0></hintbox>",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddingInline: 5,
      paddingBlock: "0px",
      borderColor: "#1eb26a",
      color: "#57585a",
      styleElementHintBox: {
        // background: "#2bbbf1",
        marginRight: 20,
        padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "<span class='strikediag'>brush my teeth</span>",
          "do homework",
          "have a snack",
          "catch the bus",
        ],
        width: "20cm",
      },
    ],
    // character: "",
    component: T6,
    questions: [
      {
        title: `
          
          <div style='display:grid; grid-template-columns: 1fr 1fr; grid-gap: 50px; margin-top: 20px'>
            <div style='display: flex; flex-direction: column; gap: 5px; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E2/1.jpg'/>
              <span><u style='color: gray; text-underline-offset: 5px; font-size: 20px'>&nbspI brush my teeth every morning.&nbsp</u></span>
            </div>
            <div style='display: flex; flex-direction: column; gap: 5px; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E2/2.jpg'/>
              # 
            </div>
            <div style='display: flex; flex-direction: column; gap: 5px; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E2/3.jpg'/>
              # 
            </div>
            <div style='display: flex; flex-direction: column; gap: 5px; align-items: center'>
              <img style='width: 100%' src='img/FriendsPlus/Page8/E2/4.jpg'/>
              # 
            </div>
          </div>
         

        `,
        answer: [
          "I catch the bus next.",
          "I do my homework after school.",
          "I have a snack at 11a.m",
        ],
      },
    ],
  },
};

export default json;
