import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P23-E1",
    audio: "Audios/Page22/audio-e3.mp3",
    video: "Videos/Unit 03.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='32' src='Audios/Page22/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page22/E3/1.jpg",
          audioUrl: "Audios/Page22/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page22/E3/2.jpg",
          audioUrl: "Audios/Page22/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page22/E3/3.jpg",
          audioUrl: "Audios/Page22/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page22/E3/4.jpg",
          audioUrl: "Audios/Page22/hinh4.mp3",
        },
      ],
      [
        {
          audioUrl: "Audios/Page22/hinh5.mp3",
          url: "img/FriendsPlus/Page22/E3/5.jpg",
        },
        {
          audioUrl: "Audios/Page22/hinh6.mp3",
          url: "img/FriendsPlus/Page22/E3/6.jpg",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P23-E2",
    audio: "Audios/Page23/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='33' src='Audios/Page23/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page23/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page23/E2/2.jpg" },

        {
          url: "img/FriendsPlus/Page23/E2/3.jpg",
          audioUrl: "Audios/Page23/hinh1.mp3",
        },
        { url: "img/FriendsPlus/Page23/E2/4.jpg" },

        {
          url: "img/FriendsPlus/Page23/E2/5.jpg",
          audioUrl: "Audios/Page23/hinh2.mp3",
        },

        { url: "img/FriendsPlus/Page23/E2/6.jpg",
        audioUrl: "Audios/Page23/hinh3.mp3",
      },

        {
          url: "img/FriendsPlus/Page23/E2/7.jpg",
          audioUrl: "Audios/Page23/hinh5.mp3",
        },
        { url: "img/FriendsPlus/Page23/E2/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page23/E2/9.jpg" }],
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P23-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Look and say. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page23/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P23-E4",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page23/E4/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    // maxLength: 17,
     
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 250,
    titleQuestion: [
      {
        num: "4",
        title:
          "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
				  <img  src='img/FriendsPlus/Page23/E4/1.jpg'/>
          <div style='width:50%;margin:20px;line-height: 50px'>
				  		<b>1</b>&ensp;What<u style='color:rgb(25 181 239)' >&ensp;does she like&ensp;</u>? She <u style='color:rgb(25 181 239)' >&ensp;likes&ensp;</u>bubble tea. <input id='0' width='225px'></input>orange juice.<br>
				  		<b>2</b>&ensp;#?	He<input id='1' width='175px'></input>chicken.<input id='2' width='125px'></input>fries.<br>
				  		

				  </div>
				</div>
        
        `,
        answer: ["She doesn't like", "What does he like", "doesn't like", "He likes"],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P23-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    titleQuestion: [
      {
        num: "5",
        title:
          "Look and say. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page23/E5/1.jpg" }]],
  },
  6: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P23-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look and say. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page23/E6/1.jpg" }]],
  },
};

export default json;
