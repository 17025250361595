import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P77-E1",
    audio: "Audios/Page76/audio-e3.mp3",
    video: "Videos/Unit 10.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='122' src='Audios/Page76/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page76/E3/1.jpg",
          audioUrl: "Audios/Page76/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E3/2.jpg",
          audioUrl: "Audios/Page76/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page76/E3/3.jpg",
          audioUrl: "Audios/Page76/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E3/4.jpg",
          audioUrl: "Audios/Page76/hinh4.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P77-E2",
    audio: "Audios/Page77/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='123' src='Audios/Page77/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {  url: "img/FriendsPlus/Page77/E2/1.jpg" },],[
        {  url: "img/FriendsPlus/Page77/E2/2.jpg" },
        {  url: "img/FriendsPlus/Page77/E2/3.jpg",audioUrl: "Audios/Page77/dong1.mp3" },
        {  url: "img/FriendsPlus/Page77/E2/4.jpg",audioUrl: "Audios/Page77/dong2.mp3" },
        {  url: "img/FriendsPlus/Page77/E2/5.jpg" },],[
        {  url: "img/FriendsPlus/Page77/E2/6.jpg" },
        {  url: "img/FriendsPlus/Page77/E2/7.jpg",audioUrl: "Audios/Page77/dong3.mp3" },
        {  url: "img/FriendsPlus/Page77/E2/8.jpg",audioUrl: "Audios/Page77/dong4.mp3" },
        {  url: "img/FriendsPlus/Page77/E2/9.jpg" },],[
        { url: "img/FriendsPlus/Page77/E2/10.jpg" },
        { url: "img/FriendsPlus/Page77/E2/11.jpg",audioUrl: "Audios/Page77/dong5.mp3" },
        { url: "img/FriendsPlus/Page77/E2/12.jpg",audioUrl: "Audios/Page77/dong6.mp3" },
        { url: "img/FriendsPlus/Page77/E2/13.jpg" },],[
        { url: "img/FriendsPlus/Page77/E2/14.jpg" },
        { url: "img/FriendsPlus/Page77/E2/15.jpg",audioUrl: "Audios/Page77/dong7.mp3" },
        { url: "img/FriendsPlus/Page77/E2/16.jpg",audioUrl: "Audios/Page77/dong8.mp3" },
        { url: "img/FriendsPlus/Page77/E2/17.jpg" },],[
        { url: "img/FriendsPlus/Page77/E2/18.jpg" },
        { url: "img/FriendsPlus/Page77/E2/19.jpg",audioUrl: "Audios/Page77/dong9.mp3" },
        { url: "img/FriendsPlus/Page77/E2/20.jpg",audioUrl: "Audios/Page77/dong10.mp3" },
        { url: "img/FriendsPlus/Page77/E2/21.jpg" },],[
        { url: "img/FriendsPlus/Page77/E2/22.jpg" },
        { url: "img/FriendsPlus/Page77/E2/23.jpg",audioUrl: "Audios/Page77/dong11.mp3" },
        { url: "img/FriendsPlus/Page77/E2/24.jpg" },
      ]
    ],
  },
  3: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P77-E3",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "3",
        title:
          "Look, read, and write T(true) or F(false).  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    exerciseKey: "img/FriendsPlus/Page77/E3/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    textAlign: "center",

    inputSize: 50,

    // isHello: true,
    questions: [
      {
        title: `
          <div style='display:flex;line-height:50px'>
            <img src='img/FriendsPlus/Page77/E3/1.jpg'>
            <div style='margin:15px 10px'>
              <b>1.</b>&ensp;The girl likes reading comics. <u style='color:rgb(25 181 239)' >&ensp;T&ensp;</u><br>
              <b>2.</b>&ensp;Dad doesn’t like taking photos. #<br>
              <b>3.</b>&ensp;The boys don’t like playing basketball. #<br>
              <b>4.</b>&ensp;Mom likes painting. #

            </div>
          </div>
        
        `,
        answer: ["T", "F", "T"],
      },
    ],
  },
  4: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P77-E4",
    audio: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page77/E4/Key/answerKey.png",
    maxLength: 32,
    titleQuestion: [
      {
        num: "4",
        title:
          "Write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page77/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page77/E4/2.jpg" },
        {
          url: "img/FriendsPlus/Page77/E4/3.jpg",
          input: true,
          answer: "Yes, we do.",
        },
      ],
      [{ url: "img/FriendsPlus/Page77/E4/4.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page77/E4/5.jpg",
          input: true,
          answer: "Do they like playing badminton?",
        },
        { url: "img/FriendsPlus/Page77/E4/6.jpg" },
        {
          url: "img/FriendsPlus/Page77/E4/7.jpg",
          input: true,
          answer: "Does he like playing volleyball?",
        },
      ],
      [{ url: "img/FriendsPlus/Page77/E4/8.jpg" }],
    ],
  },
  5: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P77-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page77/E5/1.jpg" }]],
  },
};

export default json;
