import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P65-E1",
    audio: "Audios/Page65/audio-e1.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page65/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and match. <headphone name='104' src='Audios/Page65/tieude-e1.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    component: T6,
    maxLength: 1,
    inputSize: 40,
    textareaStyle: { width: 800 },
    textAlign: "center",
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div style='display:flex; justify-content: space-around'>
          <div style='margin: 0px 25px'>
            <img  src='img/FriendsPlus/Page65/E1/1.jpg'/>
            <div style=';margin-bottom:25px;text-align:center'>
                Trung <u style='color:rgb(25 181 239)' >&ensp;c&ensp;</u>
            </div>
            <img  src='img/FriendsPlus/Page65/E1/5.jpg'/>
          </div>
          <div>
            <img  src='img/FriendsPlus/Page65/E1/2.jpg'/>
            <div style=';margin-bottom:25px;text-align:center'>
              Linh #
            </div>
            <img  src='img/FriendsPlus/Page65/E1/6.jpg'/>
          </div>
          <div style='margin: 0px 25px'>
            <img  src='img/FriendsPlus/Page65/E1/3.jpg'/>
            <div style=';margin-bottom:25px;text-align:center'>
              Vinh #
            </div>
            <img  src='img/FriendsPlus/Page65/E1/7.jpg'/>
          </div>
          <div>
            <img  src='img/FriendsPlus/Page65/E1/4.jpg'/>
            <div style=';margin-bottom:25px;text-align:center'>
              Kim #
            </div>
            <img  src='img/FriendsPlus/Page65/E1/8.jpg'/>
          </div>
        </div>
        
        `,
        answer: ["a", "d", "b"],
      },
    ],
  },

  2: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P65-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title: "Point, ask and answer.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page65/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P65-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title: "Ask and answer with a friend.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page65/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P65-E4",
    audio: "",
    video: "",
    // exerciseKey: "img/FriendsPlus/Page65/E4/Key/answerKey.png",
    component: T6,
    inputSize: 40,
    textAlign: "center",
    // maxLength: 1,
    stylesTextInput: {
      height: 40,
      borderRadius: 10,
      border: "2px solid",
    },
    hideBtnFooter:true,
    textareaStyle: { width: 947 },
    // maxLength: ,
    titleQuestion: [
      {
        num: "4",
        title:
          "Draw and write a thank-you card for a family member. Look at the example to help you. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questions: [
      {
        title: `
      <div style='position: relative;'>
        <img  src='img/FriendsPlus/Page65/E4/1.jpg'/>
        <span style='position: absolute; top: 307px; left:63px'>
            <textarea id='0' rows='4'></textarea>
        </span>
      </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P65-E5",
    audio: "",
    video: "",
    recorder: true,
    component: T6,
    textareaStyle: { width: 500 },
    titleQuestion: [
      {
        num: "5",
        title:
          "You are on vacation. Write a postcard to a friend. Look at page 42 to help you.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        
          <div style='position: relative;margin-left:20px'>
              <img style='margin-left:100px' src='img/FriendsPlus/Page42/E1/1.jpg'/>
            <span style='position: absolute; top: 420px; left:-80px'>
              <textarea id='0' rows='4'></textarea>
            
            </span>
            
            
          </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
