import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P20-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page20/E1/Key/answerKey.png",
    component: T6,
    maxLength: 47,
    inputSize: 550,
    titleQuestion: [
      {
        num: "1",
        title: "Look. Does she work in an office? What do you see?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page20/E1/1.jpg'/>
        <div>
          Does she work in an office?<br>#<br>What do you see?<br>#
        </div>
        
        
        `,
        answer: [
          "No, she doesn’t.",
          "She works in a zoo. Elephants, lions, giraffes.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P20-E2",
    audio: "Audios/Page20/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='28' src='Audios/Page20/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page20/E1/1.jpg" }]],
  },
  3: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P20-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page20/E3/Key/answerKey.png",
    component: T6,
    maxLength: 7,
    textAlign: "center",
    inputSize: 45,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read again and write T (true) or F (false). <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page20/E1/1.jpg'/>
        <div >
          <b>1</b>&ensp;Vy works in a police station. <u style='color:rgb(25 181 239)' >&ensp;F&ensp;</u>
          </br><b>2</b>&ensp;She doesn’t take care of little animals. #
          </br><b>3</b>&ensp;She start work in the afternoon. #
          </br><b>4</b>&ensp;The animals eat all their food. #
          </br><b>5</b>&ensp;She doesn’t go home in the morning. #

        </div>
        `,
        answer: ["T", "F", "T", "T"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P20-E4",
    audio: "",
    video: "",
     
    exerciseKey: "",
    component: T6,
    maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "4",
        title:
          "Think about a zoo animal. What does it eat? What doesn’t it eat?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P20-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page20/E5/1.jpg" }]],
  },
};

export default json;
