import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P89-E1",
    audio: "Audios/Page88/audio-e3.mp3",
    video: "Videos/Unit 12.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='143' src='Audios/Page88/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page88/E3/1.jpg",
          audioUrl: "Audios/Page88/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E3/2.jpg",
          audioUrl: "Audios/Page88/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page88/E3/3.jpg",
          audioUrl: "Audios/Page88/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E3/4.jpg",
          audioUrl: "Audios/Page88/hinh4.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P89-E2",
    audio: "Audios/Page89/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='144' src='Audios/Page89/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page89/E2/1.jpg" },],[
        { url: "img/FriendsPlus/Page89/E2/2.jpg" },
        { url: "img/FriendsPlus/Page89/E2/3.jpg",audioUrl: "Audios/Page89/cau1.mp3" },
        { url: "img/FriendsPlus/Page89/E2/4.jpg" },],[
        { url: "img/FriendsPlus/Page89/E2/5.jpg" },
        { url: "img/FriendsPlus/Page89/E2/6.jpg",audioUrl: "Audios/Page89/cau2.mp3" },
        { url: "img/FriendsPlus/Page89/E2/7.jpg" },],[
        { url: "img/FriendsPlus/Page89/E2/8.jpg" },],[
        { url: "img/FriendsPlus/Page89/E2/9.jpg" },
        { url: "img/FriendsPlus/Page89/E2/10.jpg",audioUrl: "Audios/Page89/cau3.mp3" },
        { url: "img/FriendsPlus/Page89/E2/11.jpg" },],[
        { url: "img/FriendsPlus/Page89/E2/12.jpg" },
        { url: "img/FriendsPlus/Page89/E2/13.jpg",audioUrl: "Audios/Page89/cau4.mp3" },
        { url: "img/FriendsPlus/Page89/E2/14.jpg" },],[
        { url: "img/FriendsPlus/Page89/E2/15.jpg" },
    ]
    ],
  },
  3: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P89-E3",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "3",
        title:
          "Read and circle.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    exerciseKey: "img/FriendsPlus/Page89/E3/Key/answerKey.png",
    component: Circle_Write,
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          borderRadius: "50%",
          borderColor: "#00aeef",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: 5,
          border: "solid 3px",
        },
        limitSelect: 1,
        listWords: [
          "were / weren’t",
          "was / wasn’t", //2
          "was / wasn’t", //3
          "were / weren’t",
          "were / weren’t",
        ],
        answers: ["0-4", "1-4", "2-0", "3-0", "4-4"],
        initialValue: ["0-4"],
      },
      Layout: `
      <div style='line-height:50px'>
        <b>1</b> Nam <input id='0' borderColor='red' type='Circle' /> in the zoo.<br>
        <b>2</b> Nam <input id='1' type='Circle' /> the big animals.<br>
        <b>3</b> His favorite animals are the monkeys <input id='2' type='Circle' />.<br>
        <b>4</b> There are <input id='3' type='Circle' /> monkeys at the zoo.<br>
        <b>5</b> He <input id='4' type='Circle' /> the parrots.<br>
      </div>
      `,
    },
  },
  4: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P89-E4",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page89/E4/Key/answerKey.png",
    maxLength: 32,
    titleQuestion: [
      {
        num: "4",
        title:
          "Look and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    textAlign: "center",

    inputSize: 110,

    // isHello: true,
    questions: [
      {
        title: `
          
            <div style='display:flex;width:20cm' >
              <div>
                <img  src='img/FriendsPlus/Page89/E4/1.jpg'/>
                <div style='text-align:center; width:90%'>
                  They<u style='color:rgb(25 181 239)' >&ensp;were&ensp;</u>at a 
                  café yesterday. They#at school.
                </div>
              </div>

              <div style='margin:0px 20px'>
                <img  src='img/FriendsPlus/Page89/E4/2.jpg'/>
                <div style='text-align:center; width:90%'>
                  They#at a party last weekend. They#at home.
                </div>
              </div>

              <div style='margin-right: 20px'>
                <img  src='img/FriendsPlus/Page89/E4/3.jpg'/>
                <div style='text-align:center; width:85%'>
                  Nam#at guitar class last night. He#at piano class.
                </div>
              </div>

              <div >
                <img  src='img/FriendsPlus/Page89/E4/4.jpg'/>
                <div style='text-align:center; width:85%'>
                  We#at the zoo. We#at the park.
                </div>
              </div>
            </div >
              
          
        
        `,
        answer: [
          "weren’t",
          "were",
          "weren’t",
          "wasn’t",
          "was",
          "weren’t",
          "were",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P89-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page89/E5/1.jpg" }]],
  },
};

export default json;
