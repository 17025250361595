import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P71-E1",
    audio: "Audios/Page71/audio-e1.mp3",
    video: "",
    component: D1,
    maxLength: 1,
    // fontSize: 27,
    exerciseKey: "img/FriendsPlus/Page71/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and match. <headphone name='114' src='Audios/Page71/tieude-e1.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page71/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page71/E1/2.jpg" },
        { url: "img/FriendsPlus/Page71/E1/3.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page71/E1/4.jpg" },
        { url: "img/FriendsPlus/Page71/E1/5.jpg", input: true, answer: "c" },
        { url: "img/FriendsPlus/Page71/E1/6.jpg" },
        { url: "img/FriendsPlus/Page71/E1/7.jpg", input: true, answer: "b" },
      ],
      [{ url: "img/FriendsPlus/Page71/E1/8.jpg" }],
    ],
  },

  2: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P71-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title: "Look and say.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page71/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P71-E3",
    audio: "",
    video: "",

    titleQuestion: [
      {
        num: "3",
        title: "Talk about your family’s day.",
        color: "#203c8f",
      },
    ],

    component: UI,
    recorder: true,

    questionImage: [[{ url: "img/FriendsPlus/Page71/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P71-E4",
    audio: "",
    video: "",
    // exerciseKey: "img/FriendsPlus/Page71/E4/Key/answerKey.png",
    inputSize: 40,
    hideBtnFooter:true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Write about activites in your day. You can start with the example. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    // maxLength: 6,
    // textAlign: "center",
    component: D1,
    stylesTextInput: {},
    fontSize: 26,
    // recorder: true,

    questionImage: [
      [{ url: "img/FriendsPlus/Page71/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page71/E4/2.jpg" },
        { url: "img/FriendsPlus/Page71/E4/3.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page71/E4/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page71/E4/5.jpg" },
        { url: "img/FriendsPlus/Page71/E4/6.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page71/E4/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page71/E4/8.jpg" },
        { url: "img/FriendsPlus/Page71/E4/9.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page71/E4/10.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page71/E4/11.jpg" },
        { url: "img/FriendsPlus/Page71/E4/12.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page71/E4/13.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page71/E4/14.jpg" }],
    ],
  },
  5: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P71-E5",
    audio: "",
    video: "",
    recorder: true,
    component: T6,
    textareaStyle: { width: 500 },
    titleQuestion: [
      {
        num: "5",
        title:
          "You are on vacation. Write a postcard to a friend. Look at page 42 to help you.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        
          <div style='position: relative;margin-left:20px'>
              <img style='margin-left:100px' src='img/FriendsPlus/Page42/E1/1.jpg'/>
            <span style='position: absolute; top: 420px; left:-80px'>
              <textarea id='0' rows='4'></textarea>
            
            </span>
            
            
          </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
