import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Match_Write from "../../components/ExcerciseTypes/Match_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  // 1: {
  //   unit: "Review 3",
  //   id: "SB5-2024-R3-P72-E1",
  //   audio: "",
  //   video: "",
  //   component: D1,
  //   textAlign: "center",
  //   exerciseKey: "img/FriendsPlus/Page72/E1/Key/answerKey.png",
  //   maxLength: 1,
  //   // isAllowSubmit: false,
  //   titleQuestion: [
  //     {
  //       num: "1",
  //       title:
  //         "Listen and write the numbers. <headphone name='79' src='Audios/Page72/tieude-e1.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
  //       color: "#203c8f",
  //     },
  //   ],
  //   fontSize: 26,
  //   question: [],
  //   questionImage: [
  //     [{ url: "img/FriendsPlus/Page72/E1/1.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page72/E1/2.jpg" },
  //       { url: "img/FriendsPlus/Page72/E1/3.jpg", input: true, answer: "8" },
  //       { url: "img/FriendsPlus/Page72/E1/4.jpg" },
  //       { url: "img/FriendsPlus/Page72/E1/5.jpg", input: true, answer: "2" },
  //       { url: "img/FriendsPlus/Page72/E1/6.jpg" },
  //       { url: "img/FriendsPlus/Page72/E1/7.jpg", input: true, answer: "3" },
  //       { url: "img/FriendsPlus/Page72/E1/8.jpg" },
  //       { url: "img/FriendsPlus/Page72/E1/9.jpg", input: true, answer: "5" },
  //     ],
  //     [{ url: "img/FriendsPlus/Page72/E1/10.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page72/E1/11.jpg" },
  //       { url: "img/FriendsPlus/Page72/E1/12.jpg", input: true, answer: "7" },
  //       { url: "img/FriendsPlus/Page72/E1/13.jpg" },
  //       { url: "img/FriendsPlus/Page72/E1/14.jpg", input: true, answer: "6" },
  //       { url: "img/FriendsPlus/Page72/E1/15.jpg" },
  //       { url: "img/FriendsPlus/Page72/E1/16.jpg", input: true, answer: "4" },
  //       { url: "img/FriendsPlus/Page72/E1/17.jpg" },
  //     ],
  //   ],
  // },
  3: {
    unit: "Review 3",
    id: "SB5-2024-R3-P72-E1",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    maxLength:5,
    exerciseKey: "img/FriendsPlus/Page72/E3/Key/answerKey.png",
    inputSize: 100,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read and draw the times. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page72/E3/1.jpg' > <br>
        <div style='line-height:50px;display:flex;justify-content:space-between'>
          <div><b>1.</b>&ensp;#</div>
          <div><b>2.</b>&ensp;#</div>
          <div><b>3.</b>&ensp;#</div>
          <div><b>4.</b>&ensp;#</div>
        </div>
        `,
        answer: ["7:00","7:45", "12:15", "6:00"],
      },
    ],
  },
  1: {
    unit: "Review 3",
    id: "SB5-2024-R3-P72-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page72/E1/Key/answerKey.png",
    component: T6,
    maxLength: 6,
    textAlign: "center",
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title:
          "Look and write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
              <div>
                <img src='img/FriendsPlus/Page72/E1/1.jpg' > <br>
                 <b>1.</b>&ensp;wonsy
                <div style='text-align:center'>
                  <u style='color:rgb(25 181 239)' >&ensp;snowy&ensp;</u>
                </div>
              </div>
              <div style='margin:0px 40px'>
                <img src='img/FriendsPlus/Page72/E1/2.jpg' > <br>
                 <b>2.</b>&ensp;oht
                <div style='text-align:center'>
                  #
                </div>
              </div>
              <div>
                <img src='img/FriendsPlus/Page72/E1/3.jpg' > <br>
                 <b>3.</b>&ensp;locd
                <div style='text-align:center'>
                  #
                </div>
              </div>
         </div>
        <div style='display:flex;margin: 20px 0px'>
              <div>
                <img src='img/FriendsPlus/Page72/E1/4.jpg' > <br>
                 <b>4.</b>&ensp;diwyn
                <div style='text-align:center'>
                  #
                </div>
              </div>
              <div style='margin:0px 40px'>
                <img src='img/FriendsPlus/Page72/E1/5.jpg' > <br>
                 <b>5.</b>&ensp;unsny
                <div style='text-align:center'>
                  #
                </div>
              </div>
              <div>
                <img src='img/FriendsPlus/Page72/E1/6.jpg' > <br>
                 <b>6.</b>&ensp;aniry
                <div style='text-align:center'>
                  #
                </div>
              </div>
         </div>
        `,
        answer: ["hot", "cold", "windy", "sunny", "rainy"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Review 3",
    id: "SB5-2024-R3-P72-E2",
    audio: "Audios/Page72/audio-e2.mp3",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page72/E2/Key/answerKey.png",
    // <headphone name='116' src='Audios/Page71/tieude-e1.mp3'></headphone>
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and circle. What do they like? <headphone name='115' src='Audios/Page72/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          fontWeight: 600,
          color: "",
          borderRadius: "50%",
        },
        selectWordStyle: { border: "2px solid black" },
        limitSelect: 1,
        listWords: [
          "cake / candy",
          "candles / balloons",
          "candy / chocolate",
          "gift_card / present",
        ],
        answers: ["0-4", "1-0", "2-4", "3-0"],

        initialValue: [],
      },
      Layout: `
          <div style='display:flex;line-height:50px'>
            <div>
              <b>1.</b>&ensp;Duc<br>
              <b>2.</b>&ensp;Nhan<br>
              <b>3.</b>&ensp;Hoa<br>
              <b>4.</b>&ensp;Huy
            </div>
            <div style='margin-left:100px'>
              <input id='0'  type='Circle' /><br>
              <input id='1'  type='Circle' /><br>
              <input id='2'  type='Circle' /><br>
              <input id='3'  type='Circle' />
            </div>
          </div>
          
        `,
    },
  },
  4: {
    unit: "Review 3",
    id: "SB5-2024-R3-P72-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page72/E4/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title:
          "<img src='img/FriendsPlus/Page72/E4/15.jpg' /> <br> <img src='img/FriendsPlus/Page72/E4/1.jpg' /> ",
        color: "",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "235px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "235px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
        ],
        answers: ["0-5", "1-4", "2-7", "3-6"],
        initialValue: [],
      },
      Layout: `
        <div style="font-size: 30px;">
					

          <div style='display: flex;padding-top: 5mm'>
            <div ><b>1</b> I don’t like fish.</div>
						<div><input id='5' type= 'boxMatch' /></div>
            <div><input id='1' type= 'boxMatch' /></div>
						<div style='margin-left: 380px;'><b>a.</b> They are not my dogs.</div>
          </div>

					<div style='display: flex;padding-top: 5mm'>
            <div><b>2</b> It isn’t banana.</div>
						<div><input id='2' type= 'boxMatch' /></div>
            <div><input id='3' type= 'boxMatch' /></div>
						<div style='margin-left: 383px;'><b>b.</b> I do not have blue eyes.</div>
          </div>
					
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>3</b>  They aren’t my dogs.</div>
						<div><input id='4' type= 'boxMatch' /></div>
            <div><input id='0' type= 'boxMatch' /></div>
						<div style='margin-left: 300px;'><b>c.</b> I do not like fish.</div>
          </div>
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>4</b> I don’t have blue eyes.</div>
						<div><input id='6' type= 'boxMatch' /></div>
            <div><input id='7' type= 'boxMatch' /></div>
						<div style='margin-left: 278px;'><b>d.</b> It is not a banana.</div>
          </div>
        </div>
      `,
    },
  },
  // 4: {
  //   unit: 'Review 3',
  //   id: 'SB5-2024-R3-P72-E1',
  //   audio: '',
  //   video: '',
  //   component: Match_Write,
  //   exerciseKey: 'img/FriendsPlus/Page72/E4/Key/answerKey.png',
  //   titleQuestion: [
  //     {
  //       num: '',
  //       title: "<img src='img/FriendsPlus/Page72/E4/15.jpg' /> <br> <img src='img/FriendsPlus/Page72/E4/1.jpg' /> ",
  //       color: '',
  //     },
  //   ],
  //   question: {
  //     Match: {
  //       answers: ['0-5', '1-4', '2-7', '3-6'],
  //       coordinates: [
  //         { x: 222, y: 200 },
  //         { x: 444, y: 200 },
  //         { x: 666, y: 200 },
  //         { x: 888, y: 200 },
  //         { x: 222, y: 400 },
  //         { x: 444, y: 400 },
  //         { x: 666, y: 400 },
  //         { x: 888, y: 400 },
  //       ],
  //       // height: 600,
  //       isHorizontal: false,
  //       width: 1110,
  //     },
  //     Write: {
  //       answers: ['hello', 'hi', 'now', 'future'],
  //       commonStyles: {},
  //     },
  //     Layout: `
  //       <div>
  //       <input id='0' />
  //       <input id='1' />
  //       <canvas></canvas>
  //       <input id='2' />
  //       <input id='3' width='50px' />
  //       </div>
  //     `,
  //   },
  // },
};
export default json;
