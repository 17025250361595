import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB3V2-U1-P15-E1",
    audio: "Audios/Page15/E1/audio-e1.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page15/E1/Key/answerKey.png",
    component: D1,
    maxLength: 1,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write the numbers. <headphone name='18' src='Audios/Page15/E1/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page15/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page15/E1/2.jpg" },
        { url: "img/FriendsPlus/Page15/E1/3.jpg", input: true, answer: "4" },
        { url: "img/FriendsPlus/Page15/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page15/E1/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page15/E1/6.jpg" },
        { url: "img/FriendsPlus/Page15/E1/7.jpg", input: true, answer: "3" },
        { url: "img/FriendsPlus/Page15/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page15/E1/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page15/E1/10.jpg" },
        { url: "img/FriendsPlus/Page15/E1/11.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page15/E1/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page15/E1/13.jpg" }],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB3V2-U1-P15-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title: "Point and say.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page15/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 1",
    id: "SB3V2-U1-P15-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title: "Say the jobs that your family members do.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page15/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 1",
    id: "SB3V2-U1-P15-E4",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dash" },
    inputSize: 350,
    questionImage: [],
    titleQuestion: [
      {
        num: "4",
        title:
          "Write about you and your family. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    isHello: true,
    questions: [
      {
        title: `
        <div style='line-height:55px' >
          My name's# .<br> 
          I’m a#.<br>
          My mom is #.<br>
        </div>
        
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB7-U1-P15-E5",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dash" },
    inputSize: 400,
    questionImage: [],
    isHello: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Read your sentences from <span style='color:#233f8e'>4</span>. Circle the capital letters and periods. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page15/E5/1.jpg' />
       
        
        `,
        answer: [],
      },
    ],
  },
};

export default json;
