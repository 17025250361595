import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P26-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/E1/Key/answerKey.png",
    component: T6,
    maxLength: 7,
    inputSize: 250,
    titleQuestion: [
      {
        num: "1",
        title: "Look at the pictures. What food does the boy like?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div>
          #
        </div>
        <img src='img/FriendsPlus/Page26/E1/1.jpg'/>
        
        
        `,
        answer: ["bananas, rice / rice, bananas"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P26-E2",
    audio: "Audios/Page26/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='28' src='Audios/Page26/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page26/E2/1.jpg",
          audioUrl: "Audios/Page26/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page26/E2/2.jpg",
          audioUrl: "Audios/Page26/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page26/E2/3.jpg",
          audioUrl: "Audios/Page26/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page26/E2/4.jpg",
          audioUrl: "Audios/Page26/hinh4.mp3",
        },
      ],
      [
        {
          audioUrl: "Audios/Page26/hinh5.mp3",
          url: "img/FriendsPlus/Page26/E2/5.jpg",
        },
        {
          audioUrl: "Audios/Page26/hinh6.mp3",
          url: "img/FriendsPlus/Page26/E2/6.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P26-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/E3/Key/answerKey.png",
    component: T6,
    maxLength: 7,
    textAlign: "center",
    inputSize: 45,
    selectStyle: {
      width: 45,
      height: 45,
      textAlign: "center",
      fontSize: 27,
    },
    selectOptionRandom: true,
    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "3",
        title:
          "What food do they have? Put a check (✓) or cross (✗) in the box. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],

    // isHello: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page26/E1/1.jpg' />
        <div style='display:flex; justify-content: space-between'>
          <div style='display:flex'>
            <b>1</b>. chicken &ensp;<select id=0></select>
          </div>
          <div style='display:flex'>
            <b>2</b>. bananas &ensp;<select id=1></select>
          </div>
          <div style='display:flex'>
            <b>3</b>. rice &ensp;<select id=2></select>
          </div>
          <div style='display:flex'>
            <b>4</b>. noodles &ensp;<select id=3></select>
          </div>
        </div>
        `,
        answer: ["✗", "✓", "✓", "✗"],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P26-E4",
    audio: "",
    video: "",
     
    exerciseKey: "",
    component: T6,
    maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "4",
        title: "What food does your friend like?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P26-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page26/E5/1.jpg" }]],
  },
};

export default json;
