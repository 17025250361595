import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P52-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page52/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Read and write. Use the words from the word box.",
        color: "#203c8f",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    stylesTextInput: { background: "none", width: 170 },
    textAlign: "center",
    questions: [
      {
        title: `
          <div style='display:flex'>
            <div style='width:180mm'>
              In 2009, a farmer named Ho Khanh and some <sup>1</sup><u style='color:gray'>&ensp;scientists&ensp;</u> scientists 
              found Son Doong – a huge <sup>2</sup># . They were 
              surprised because they found a <sup>3</sup># and 
              a <sup>4</sup># inside. <sup>5</sup># can visit 
              the cave, but it’s not an easy trip because it’s so big! 
            </div>
            <img style='height:7cm' src='img/FriendsPlus/Page52/E1/1.jpg'/>
          </div>

          
          `,
        answer: [
          "cave",
          "jungle|jungle / river|river / jungle|river",
          "jungle|jungle / river|river / jungle|river",
          "Tourists",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P52-E2",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page52/Key/E2answerKey.png",
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title: "Read the text in the Student Book. Fill in the correct circle.",
        color: "#203c8f",
        left: -50,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0px 9px",
          border: "2px solid",
          color: "white",
          // background: "gray",
          borderRadius: "50%",
          borderColor: "gray",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          width: 10,
          borderRadius: "50%",
          background: "gray",
          color: "gray",
          border: "2px solid",
          borderColor: "gray",
        },
        limitSelect: 1,
        listWords: [
          "a <br> a <br> a",
          "a <br> a <br> a",
          "a <br> a <br> a",
          "a <br> a <br> a",
        ],
        answers: ["0-0", "1-8", "2-4", "3-8"],
        initialValue: ["0-0"],
      },
      Layout: `
      
    <b>1.</b>&ensp;What is the reading about?<br>
     <div style='display:flex;line-height:45px'>
        <div style='margin-left:35px;margin-right: 55px'>
            (A)&ensp;a big cave<br>
            (B)&ensp;Mr. Ho Khanh and his family<br>
            (C)&ensp;an interesting vacation<br> 
        </div>
        <div>
          <input id=0 select='Tick' type='Circle'/>
        </div>
     </div>
    <b>2.</b>&ensp;Who found the cave first?<br>
     <div style='display:flex;line-height:45px'>
        <div style='margin-left:35px;margin-right: 213px'>
          (A) some scientists<br>
          (B) some tourists<br>
          (C) Mr. Ho Khanh<br>
        </div>
        <div>
          <input id=1 select='Tick' type='Circle'/>
        </div>
     </div>
    <b>3.</b>&ensp;Why were Mr. Khanh and the scientists surprised when they went inside?<br>
     <div style='display:flex;line-height:45px'>
        <div style='margin-left:35px;margin-right: 88px'>
            (A) They found some tourists.<br>
            (B) They found a river.<br>
            (C) They found a beach.<br> 
        </div>
        <div>
          <input id=2 select='Tick' type='Circle'/>
        </div>
     </div>
    <b>4.</b>&ensp;Why isn’t it easy for people to visit the cave?<br>
     <div style='display:flex;line-height:45px'>
        <div style='margin-left:35px;margin-right: 73px'>
            (A) They forget where it is.<br>
            (B) They think it isn’t real.<br>
            (C) It’s difficult to walk inside it.<br> 
        </div>
        <div>
          <input id=3 select='Tick' type='Circle'/>
        </div>
     </div>
      `,
    },
  },

  3: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P52-E3",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page52/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the text in the Student Book. Write one word to complete the sentence.",
        color: "#203c8f",
        numberImg: "b",
        left: 50,
      },
    ],
    stylesTextInput: { width: 170 },
    textAlign: "center",
    component: T6,
    questions: [
      {
        title: `
				<b>1.</b>&ensp;Son Doong is a huge <u style='color:gray'>&ensp;cave&ensp;</u> .<br>
        <b>2.</b>&ensp;Mr. Ho Khanh found the cave in# .<br>
        <b>3.</b>&ensp;In 2009, Mr. Khanh and some# found the cave again.<br>
        <b>4.</b>&ensp;There is a# and a# inside the cave.<br>
        
        `,
        answer: ["1991", "scientists", "river", "jungle"],
      },
    ],
  },
};

export default json;
