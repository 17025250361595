import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Select_Image from "../../components/ExcerciseTypes/Select/Select_Image";
const json = {
  1: {
    unit: "Starter",
    id: "WB5-2024-Starter-P7-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page7/Key/E1answerKey.png",
    stylesTextInput: { background: "none" },
    textAlign: "center",
    inputSize: 170,
    titleQuestion: [
      {
        num: "1",
        title: "Look and write the words.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddingInline: 5,
      paddingBlock: "0px",
      borderColor: "#2bbbf1",
      color: "#57585a",
      styleElementHintBox: {
        // background: "#2bbbf1",
        marginRight: 20,
        padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "<span class='strikediag'>doctor</span>",
          "farmer",
          "firefighter",
          "pilot",
          "police officer",
          "teacher",
        ],
        width: "20cm",
      },
    ],
    // character: "",
    component: T6,
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
          <div style='display:grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 50px; margin-top: 20px'>
            <div style='display: flex; flex-direction: column; gap: 5px'>
              <img style='width: 7cm' src='img/FriendsPlus/Page7/E1/1.jpg'/>
              <span><b>1.&nbsp</b><u style='color: gray; text-underline-offset: 5px'>&nbspdoctor&nbsp</u></span>
            </div>
            <div style='display: flex; flex-direction: column; gap: 5px'>
              <img style='width: 7cm' src='img/FriendsPlus/Page7/E1/2.jpg'/>
              <span><b>2.&nbsp</b> # </span>
            </div>
            <div style='display: flex; flex-direction: column; gap: 5px'>
              <img style='width: 7cm' src='img/FriendsPlus/Page7/E1/3.jpg'/>
              <span><b>3.&nbsp</b> # </span>
            </div>
            <div style='display: flex; flex-direction: column; gap: 5px'>
              <img style='width: 7cm' src='img/FriendsPlus/Page7/E1/4.jpg'/>
              <span><b>4.&nbsp</b> # </span>
            </div>
            <div style='display: flex; flex-direction: column; gap: 5px'>
              <img style='width: 7cm' src='img/FriendsPlus/Page7/E1/5.jpg'/>
              <span><b>5.&nbsp</b> # </span>
            </div>
            <div style='display: flex; flex-direction: column; gap: 5px'>
              <img style='width: 7cm' src='img/FriendsPlus/Page7/E1/6.jpg'/>
              <span><b>6.&nbsp</b> # </span>
            </div>
          </div>
         

        `,
        answer: ["pilot", "teacher", "firefighter", "police officer", "farmer"],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "WB5-2024-Starter-P7-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page7/Key/E2answerKey.png",
    stylesTextInput: { background: "none", border: "none" },
    // textAlign: "center",
    inputSize: 500,
    titleQuestion: [
      {
        num: "2",
        title: "Ask your friend. Make notes.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    // character: "",
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: column; width: 20cm; border: 1px solid rgb(220, 221, 223); font-size: 20px; box-shadow: 5px 5px rgb(233, 233, 233)'>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr'>
              <span style='color: rgb(0,136,202)'>Name</span>
              #
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr'>
              <span style='color: rgb(0,136,202)'>Address</span>
              #
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr'>
              <span style='color: rgb(0,136,202)'>School and class</span>
              #
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr'>
              <span style='color: rgb(0,136,202)'>Teacher’s name </span>
              #
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr'>
              <span style='color: rgb(0,136,202)'>Favorite subject? Why?</span>
              #
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr'>
              <span style='color: rgb(0,136,202)'>Free time</span>
              #
            </div>
            <div style='border-bottom: 1px solid rgb(183, 218, 239); padding: 5px 10px; display: grid; grid-template-columns: 1fr 2fr'>
              <span style='color: rgb(0,136,202)'>Dream job</span>
              #
            </div>
          </div>
        `,
        answer: [""],
      },
    ],
  },
  3: {
    unit: "Starter",
    id: "WB5-2024-Starter-P7-E3",
    audio: "",
    video: "",
    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 700,
      paddingTop: 5,
    },
    inputSize: 720,
    titleQuestion: [
      {
        num: "5",
        title: "Write about your friend.",
        color: "#203c8f",
        left: 80,
      },
    ],
    hideBtnFooter: true,
    // isHello: true,
    questions: [
      {
        title: `
          <div style='border: 3px solid rgb(64, 199, 244); padding: 10px 10px 0 10px; border-radius: 10px'>
            <textarea id='0' rows=7 ></textarea>
          </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
