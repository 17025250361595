import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P87-E1",
    audio: "Audios/Page87/audio-e1.mp3",
    video: "",
    recorder: true,
    component: UI,
    // textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and point to the route. Where is Leo going? <headphone name='140' src='Audios/Page87/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: { width: "90%", marginLeft: 50,}, 
    questionImage: [[{ url: "img/FriendsPlus/Page87/E2/1.jpg" }]],
  },

  2: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P87-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen again and trace the route to Leo’s school with your finger. ",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page87/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P87-E3",
    audio: "",
    video: "",
    component: UI,
    //recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3 ",
        title:
          "Underline the words that sound stronger.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page87/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P87-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "4 ",
        title: "Choose a place at school and give directions to a friend.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page87/E4/1.jpg" }]],
  },
  5: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P87-E5",
    audio: "",
    video: "",
    // exerciseKey: "img/FriendsPlus/Page87/E4/Key/answerKey.png",
    component: T6,
    // maxLength: 7,
    textAlign: "center",
    inputSize: 120,
    stylesTextInput:{
      background: "none",
      borderBottom: "2px dotted",
      height: 27,
      fontSize: 22,
      textTransform: 'uppercase',
      textAlight: "center",
    },
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "5",
        title:
          "Write what the children should or shouldn’t do.",
          color: "#203c8f",
          left: 50,
      },
    ],
    stylesTextInput: {
        marginBottom: 10,
        marginLeft: 0, 
        background: "none",
        width: '400px'
      },

    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddingInline: 5,
      paddingBlock: "0px",
      borderColor: "#2bbbf1",
      color: "#57585a",
      styleElementHintBox: {
        // background: "#2bbbf1",
        marginRight: 20,
        padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "bed",
          "dinner",
          "ice scream",
          "orange juice",
          "water",
        ],
        width: "17cm",
      },
    ],
    // character: "",
    // hai , ba , bốn , năm
    component: T6,
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
          <div style='display:flex'>
                <div style='width:20cm'>
                      <div style='display:flex'>
                            <b>1</b>.
                            <div style='margin-left:15px'>Mai has a headache.<u style='color:gray; text-underline-offset: 5px'>&nbsp She should drink some water. &nbsp</u></div>
                      </div>
                      <div style='display:flex'>
                            <b>2</b>.
                            <div style='margin-left:15px'>Linh has a toothache. #.</div>
                      </div>
                      <div style='display:flex'>
                            <b>3</b>.
                            <div style='margin-left:15px'>Tuan has a cold. #.</div>
                      </div>
                      <div style='display:flex'>
                            <b>4</b>.
                            <div style='margin-left:15px'>Vinh has a stomachache. #.</div>
                      </div>
                      <div style='display:flex'>
                            <b>5</b>.
                            <div style='margin-left:15px'>Thinh feels sick. #.</div>
                      </div>
                </div>
        </div>
        
        `,
        answer: [
          "She shouldn’t drink orange juice.", 
          "He should go to bed.", 
          "He shouldn’t eat ice cream", 
          "He shouldn’t eat dinner."
        ],
      },
    ],
  },
  6: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P87-E6",
    audio: "",
    video: "",
     
    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "6",
        title:
          "Underline the direction in your invite. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    isHello: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
