import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    // Exercise num
    unit: "Review 4",
    id: "SB5-2024-RV4-P94-E1",
    audio: "Audios/Page94/audio-e1.mp3",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page94/E1/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    fontSize: 25,
    maxLength: 200,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and match. <headphone name='151' src='Audios/Page94/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "25px",
              left: "246px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "25px",
              left: "404px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "74px",
              left: "223px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "74px",
              left: "404px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "122px",
              left: "219px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "122px",
              left: "404px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "175px",
              left: "201px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "175px",
              left: "404px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
        ],
        answers: ["0-3", "2-7", "1-4", "5-6"],
        initialValue: [],
      },
      Layout: `
        <div style='line-height:50px;display:flex;justify-content:space-around;font-size:25px'>
          <div style='margin:0px 200px 50px 100px'>
          
            <b>1.</b> museum<br> 
            <b>2.</b> school<br>
            <b>3.</b> library<br>
            <b>4.</b> park<br>
          </div>
          <div >
            <b>a.</b> Go straight. It’s next to the book store.<br>
            <b>b.</b> Turn left. It’s opposite the supermarket.<br>
            <b>c.</b> Turn right. It’s between the school and the train station.<br>
            <b>d.</b> It’s near the police station, opposite the hospital.<br>
          </div>
        </div>
        
          <input id='0' type='boxMatch'/>
          <input id='1' type='boxMatch'/>
          <input id='2' type='boxMatch'/>
          <input id='3' type='boxMatch'/>
          <input id='4' type='boxMatch'/>
          <input id='5' type='boxMatch'/>
          <input id='6' type='boxMatch'/>
          <input id='7' type='boxMatch'/>

      `,
    },
  },
  2: {
    // Exercise num
    unit: "Review 4",
    id: "SB5-2024-RV4-P94-E2",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page94/E2/Key/answerKey.png",
    inputSize: 120,
    maxLength: 7,
    questionImage: [],
    checkUppercase: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    hintBox: [
      {
        src: ["cabinet", "drawers", "blanket", "shelf", "<s>rug</s> ", "bed"],
        borderColor: "#468dcb",
        width: 600,
      },
    ],
    questions: [
      {
        title: `
           <div style='line-height:50px'>
            <b>1.</b>&ensp;They were old. They#young.<br>
            <b>2.</b>&ensp;He was sad. He#happy.<br>
            <b>3.</b>&ensp;I wasn’t a monkey in the play. I#a kangaroo.<br>
            <b>4.</b>&ensp;There weren’t cakes, but there#balloons.<br>
           </div>        
        
        
        
        `,
        answer: ["weren't", "wasn't", "was", "were"],
      },
    ],
  },
  3: {
    unit: "Review 4",
    id: "SB5-2024-RV4-P94-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page94/E3/Key/answerKey.png",

    titleQuestion: [
      {
        num: "3",
        title:
          "Read the text and circle the correct words. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],

    component: DesignUnderLine,
    // recorder: true,
    question: [],
    //  
    totalInput: 6,

    questionImage: [
      [{ url: "img/FriendsPlus/Page94/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page94/E3/2.jpg" },
        { url: "img/FriendsPlus/Page94/E3/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page94/E3/4.jpg" },
        { url: "img/FriendsPlus/Page94/E3/5.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page94/E3/6.jpg" },
        { url: "img/FriendsPlus/Page94/E3/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page94/E3/8.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page94/E3/9.jpg" },
        { url: "img/FriendsPlus/Page94/E3/10.jpg", input: 2 },
        { url: "img/FriendsPlus/Page94/E3/11.jpg" },
        { url: "img/FriendsPlus/Page94/E3/12.jpg", input: 2 },
        { url: "img/FriendsPlus/Page94/E3/13.jpg" },
        { url: "img/FriendsPlus/Page94/E3/14.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page94/E3/15.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page94/E3/16.jpg" },
        { url: "img/FriendsPlus/Page94/E3/17.jpg", input: 3 },
        { url: "img/FriendsPlus/Page94/E3/18.jpg" },
        { url: "img/FriendsPlus/Page94/E3/19.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page94/E3/20.jpg" },
        { url: "img/FriendsPlus/Page94/E3/21.jpg", input: 3 },
        { url: "img/FriendsPlus/Page94/E3/22.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page94/E3/23.jpg" },
        { url: "img/FriendsPlus/Page94/E3/24.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page94/E3/25.jpg" },
        { url: "img/FriendsPlus/Page94/E3/26.jpg", input: 4 },
        { url: "img/FriendsPlus/Page94/E3/27.jpg" },
        { url: "img/FriendsPlus/Page94/E3/28.jpg", input: 4 },
        { url: "img/FriendsPlus/Page94/E3/29.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page94/E3/30.jpg" },
        { url: "img/FriendsPlus/Page94/E3/31.jpg", input: 5 },
        { url: "img/FriendsPlus/Page94/E3/32.jpg" },
        { url: "img/FriendsPlus/Page94/E3/33.jpg", input: 5 },
        { url: "img/FriendsPlus/Page94/E3/34.jpg" },
        { url: "img/FriendsPlus/Page94/E3/35.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page94/E3/36.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page94/E3/37.jpg" },
        { url: "img/FriendsPlus/Page94/E3/38.jpg", input: 6 },
        { url: "img/FriendsPlus/Page94/E3/39.jpg" },
        { url: "img/FriendsPlus/Page94/E3/40.jpg", input: 6, isCorrect: true },
        { url: "img/FriendsPlus/Page94/E3/41.jpg" },
        { url: "img/FriendsPlus/Page94/E3/42.jpg", input: 6 },
        { url: "img/FriendsPlus/Page94/E3/43.jpg" },
      ],
    ],
  },
};

export default json;
