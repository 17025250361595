import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P35-E1",
    audio: "Audios/Page35/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='53' src='Audios/Page35/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page35/E1/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page35/E1/2.jpg",
          audioUrl: "Audios/Page35/tiger.mp3",
        },
        {
          url: "img/FriendsPlus/Page35/E1/3.jpg",
        },
        {
          url: "img/FriendsPlus/Page35/E1/4.jpg",
          audioUrl: "Audios/Page35/umbrella.mp3",
        },
        {
          url: "img/FriendsPlus/Page35/E1/5.jpg",
        },
        {
          url: "img/FriendsPlus/Page35/E1/6.jpg",
          audioUrl: "Audios/Page35/van.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P35-E2",
    audio: "Audios/Page35/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and sing. <headphone name='54' src='Audios/Page35/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page35/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P35-E3",
    audio: "",
    video: "",
     
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page35/E3/Key/answerKey.png",
    component: T6,
    maxLength: 8,
    inputSize: 120,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Say the words with t, u, and v. Write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page35/E2/1.jpg'/>
        <div style='text-align:center'>#,#,#,#,#,#,#</div>
        
        `,
        answer: [
          "tiger",
          "van",
          "visit",
          "tall",
          "vet",
          "under",
          "umbrella",
          // "tiger / van / visit / tall / vet / under / umbrella",
          // "tiger / van / visit / tall / vet / under / umbrella",
          // "tiger / van / visit / tall / vet / under / umbrella",
          // "tiger / van / visit / tall / vet / under / umbrella",
          // "tiger / van / visit / tall / vet / under / umbrella",
          // "tiger / van / visit / tall / vet / under / umbrella",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P35-E4",
    audio: "Audios/Page35/audio-e4.mp3",
    video: "",
     
    exerciseKey: "img/FriendsPlus/Page35/E4/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    textAlign: "center",
    inputSize: 40,
    titleQuestion: [
      {
        num: "4",
        title:
          "Listen and complete the words. <headphone name='55' src='Audios/Page35/tieude-e4.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div style='display:flex;'>
          <img style='height:41mm' src='img/FriendsPlus/Page35/E4/1.jpg'/>
            
            <div style='margin: 0px 30px;text-align:center'>
              <img  src='img/FriendsPlus/Page35/E4/2.jpg'/>
              <div>
                <u style='color:rgb(94 133 151)' >&ensp;t&ensp;</u>iger       
              </div>
            </div>
            <div  style='text-align:center'>
              <img  src='img/FriendsPlus/Page35/E4/3.jpg'/>
              <div>
                  #nder     
              </div>
            </div>
        </div>
        <div style='display:flex'>
            <div style='text-align:center'>
              <img  src='img/FriendsPlus/Page35/E4/4.jpg'/>
              <div>
                #et  
              </div>
            </div>
            <div style='margin: 0px 30px;text-align:center'>
              <img  src='img/FriendsPlus/Page35/E4/5.jpg'/>
              <div>
                  #all     
              </div>
            </div>
            <div  style='text-align:center'>
              <img  src='img/FriendsPlus/Page35/E4/6.jpg'/>
              <div>
                  #wen#y      
              </div>
            </div>
        </div>
        
        `,
        answer: ["u", "v", "t", "t","t"],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P35-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page35/E5/1.jpg" }]],
  },
};

export default json;
