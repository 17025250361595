import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P33-E1",
    audio: "Audios/Page32/audio-e3.mp3",
    video: "Videos/Unit 04.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='49' src='Audios/Page32/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page32/E3/1.jpg",
          audioUrl: "Audios/Page32/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page32/E3/2.jpg",
          audioUrl: "Audios/Page32/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page32/E3/3.jpg",
          audioUrl: "Audios/Page32/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page32/E3/4.jpg",
          audioUrl: "Audios/Page32/hinh4.mp3",
        },
      ],
      [
        {
          audioUrl: "Audios/Page32/hinh5.mp3",
          url: "img/FriendsPlus/Page32/E3/5.jpg",
        },
        {
          audioUrl: "Audios/Page32/hinh6.mp3",
          url: "img/FriendsPlus/Page32/E3/6.jpg",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P33-E2",
    audio: "Audios/Page33/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='50' src='Audios/Page33/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page33/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page33/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page33/E2/3.jpg",
          audioUrl: "Audios/Page33/hinh1.mp3",
        },
        { url: "img/FriendsPlus/Page33/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page33/E2/5.jpg",
          audioUrl: "Audios/Page33/hinh2.mp3",
        },
        { url: "img/FriendsPlus/Page33/E2/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page33/E2/7.jpg" }],
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P33-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Look and say. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page33/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P33-E4",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page33/E4/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    maxLength: 5,
     
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 80,
    titleQuestion: [
      {
        num: "4",
        title:
          "Look and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
				<img  src='img/FriendsPlus/Page33/E4/1.jpg'/>
        <div style='display:flex'>
          <div>
				    <img  src='img/FriendsPlus/Page33/E4/2.jpg'/>
             <div style='width:50%'>
                Can <u style='color:rgb(25 181 239)' >&ensp;we&ensp;</u>draw pictures, please? Yes,
                <u style='color:rgb(25 181 239)' >&ensp;you&ensp;</u>can. 
             </div> 
          </div>
          <div>
				    <img  src='img/FriendsPlus/Page33/E4/3.jpg'/>
             <div style='width:60%'>
                #I play soccer? Yes, #can.
             </div> 
          </div>
        </div>
        <div style='display:flex'>
          <div>
				    <img  src='img/FriendsPlus/Page33/E4/4.jpg'/>
             <div style='width:50%'>
                Can#sing a song now? No, you#.
             </div> 
          </div>
          <div>
				    <img  src='img/FriendsPlus/Page33/E4/5.jpg'/>
             <div style='width:50%'>
                Can#take photos, please? No, #can’t.
             </div> 
          </div>
        </div>


        
        `,
        answer: ["Can", "you", "we", "can't", "I", "you"],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB5-2024-U4-P33-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page33/E5/1.jpg" }]],
  },
};

export default json;
