import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P61-E1",
    audio: "Audios/Page60/audio-e3.mp3",
    video: "Videos/Unit 08.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='97' src='Audios/Page60/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page60/E3/1.jpg",
          audioUrl: "Audios/Page60/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E3/2.jpg",
          audioUrl: "Audios/Page60/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page60/E3/3.jpg",
          audioUrl: "Audios/Page60/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E3/4.jpg",
          audioUrl: "Audios/Page60/hinh4.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page60/E3/5.jpg",
          audioUrl: "Audios/Page60/hinh5.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E3/6.jpg",
          audioUrl: "Audios/Page60/hinh6.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P61-E2",
    audio: "Audios/Page61/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='98' src='Audios/Page61/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page61/E2/1.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page61/E2/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page61/E2/3.jpg",
          audioUrl: "Audios/Page61/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page61/E2/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page61/E2/5.jpg",
          audioUrl: "Audios/Page61/hinh2.mp3",
        },
        {
          url: "img/FriendsPlus/Page61/E2/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page61/E2/7.jpg",
          audioUrl: "Audios/Page61/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page61/E2/8.jpg",
        },
      ],
      [{ url: "img/FriendsPlus/Page61/E2/9.jpg" }],
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P61-E3",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "3",
        title:
          "Look, ask and answer.  <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    component: UI,
    recorder: true,
    question: [],

    questionImage: [[{ url: "img/FriendsPlus/Page61/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P61-E4",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page61/E4/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    maxLength: 14,

    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 170,
    titleQuestion: [
      {
        num: "4",
        title:
          "Look and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page61/E4/1.jpg'/>
        <div >
        
          <div style='line-height:50px'>
            <b>1.</b>&ensp;They like candy because<u style='color:rgb(25 181 239)' >&ensp;it’s delicious&ensp;</u>. <br>
            <b>2.</b>&ensp;He#balloon because they pop.<br>
            <b>3.</b>&ensp;She likes the present because#<br>
            <b>4.</b>&ensp;They#the cake because there is jelly inside.<br>
          </div>
        </div>
        

        
        `,
        answer: ["doesn’t like", "it’s a new toy", "don’t like"],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB5-2024-U8-P61-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page61/E5/1.jpg" }]],
  },
};

export default json;
