import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P88-E1",
    audio: "Audios/Page88/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='141' src='Audios/Page88/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page88/E1/1.jpg",
          audioUrl: "Audios/Page88/old.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/2.jpg",
          audioUrl: "Audios/Page88/young.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/3.jpg",
          audioUrl: "Audios/Page88/handsome.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/4.jpg",
          audioUrl: "Audios/Page88/pretty.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/5.jpg",
          audioUrl: "Audios/Page88/cute.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/6.jpg",
          audioUrl: "Audios/Page88/smart.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P88-E2",
    audio: "Audios/Page88/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='142' src='Audios/Page88/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page88/E1/1.jpg",
          audioUrl: "Audios/Page88/old.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/2.jpg",
          audioUrl: "Audios/Page88/young.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/3.jpg",
          audioUrl: "Audios/Page88/handsome.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/4.jpg",
          audioUrl: "Audios/Page88/pretty.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/5.jpg",
          audioUrl: "Audios/Page88/cute.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E1/6.jpg",
          audioUrl: "Audios/Page88/smart.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P88-E3",
    audio: "Audios/Page88/audio-e3.mp3",
    video: "Videos/Unit 12.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='143' src='Audios/Page88/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page88/E3/1.jpg",
          audioUrl: "Audios/Page88/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E3/2.jpg",
          audioUrl: "Audios/Page88/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page88/E3/3.jpg",
          audioUrl: "Audios/Page88/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page88/E3/4.jpg",
          audioUrl: "Audios/Page88/hinh4.mp3",
        },
      ],
    ],
  },
};

export default json;
