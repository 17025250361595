import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P53-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page53/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Read and write. Use the verbs in the simple past.",
        color: "#203c8f",
        left: 70,
      },
    ],
    stylesTextInput: { width: 174, background: "none" },
    textAlign: "center",
    questions: [
      {
        title: `
          <div style='position:relative'>
            <div style='position:absolute;top:155px;left:736px'>#</div>
            <div style='position:absolute;top:198px;left:672px'>#</div>
            <div style='position:absolute;top:285px;left:499px'>#</div>
            <div style='position:absolute;top:328px;left:262px'>#</div>
            <div style='position:absolute;top:372px;left:367px'>#</div>
            <img style='height:14cm' src='img/FriendsPlus/Page53/E1/1.jpg'/>
          </div>

          
          `,
        answer: ["saw", "knew", "ate", "bought", "thought"],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P53-E2",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page53/Key/E2answerKey.png",
    // maxLength: 5,
    // textAlign: "center",
    stylesTextInput: {
      borderBottom: "none",
      background: "none",
    },
    titleQuestion: [
      {
        num: "2",
        title: "Complete the notes.",
        color: "#203c8f",
        left: 100,
      },
    ],
    component: T6,
    isHello: true,
    questions: [
      {
        title: `
        <div style='position:relative'>
          <div style='position:absolute;top:105px;left:285px'><input id=0 width='838px'></input></div>
          <div style='position:absolute;top:150px;left:219px'><input id=1 width='904px'></input></div>
          <div style='position:absolute;top:195px;left:251px'><input id=2 width='872px'></input></div>
          <div style='position:absolute;top:240px;left:434px'><input id=3 width='689px'></input></div>
          <div style='position:absolute;top:285px;left:368px'><input id=4 width='755px'></input></div>
          <img style='height:10cm' src='img/FriendsPlus/Page53/E2/1.jpg'/>
        </div>

        
        `,
        answer: ["", "", "", "", ""],
      },
    ],
  },

  3: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P53-E3",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page53/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: "Write a tourist leaflet. Use your notes to help you.",
        color: "#203c8f",
        left: 50,
      },
    ],
    textareaStyle: { width: 670, paddingTop: 3 },
    hideBtnFooter: true,
    component: T6,
    questions: [
      {
        title: `
        <div style='border-radius:15px;border: 5px solid rgb(247,161,135);padding:20px 10px'>
				  <textarea id=0 rows=5></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
