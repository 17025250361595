import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P80-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page80/E1/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    // maxLength: 10,
    textAlign: "center",
    inputSize: 200,
    titleQuestion: [
      {
        num: "1",
        title: "Look at the profiles. Where are the children from?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div>
          <textarea id='0' rows='4'></textarea>
        </div>
        <img src='img/FriendsPlus/Page80/E1/1.jpg'/>
        
        
        `,
        answer: [""],
      },
    ],
  },
  2: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P80-E2",
    audio: "Audios/Page80/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='128' src='Audios/Page80/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page80/E1/1.jpg" }]],
  },
  3: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P80-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page80/E3/Key/answerKey.png",
    component: T6,
    maxLength: 3,
    textAlign: "center",
    inputSize: 80,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read again and write Yes or No. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <img style='height:12cm'  src='img/FriendsPlus/Page80/E1/1.jpg'/>
        <div  >
          <b>1.</b>&ensp;Does Hoang like playing the guitar? <u style='color:rgb(25 181 239)' >&ensp;Yes&ensp;</u><br>
          <b>2.</b>&ensp;Does Hoang like reading comics ? #<br>
          <b>3.</b>&ensp;Does Eun-sol like playing chess? #<br>
          <b>4.</b>&ensp;Does Eun-sol like visiting family? #<br>
          <b>5.</b>&ensp;Does Mon like playing badminton? #<br>
          <b>6.</b>&ensp;Does Mon like playing soccer? #

        </div>
        `,
        answer: ["No / n", "Yes / y", "Yes / y", "No / n", "Yes / y"],
      },
    ],
  },
  4: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P80-E4",
    audio: "",
    video: "",
     
    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "4",
        title:
          "What activities do you and your friends like doing in your free time?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page80/E4/1.jpg'/>

        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P80-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page80/E5/1.jpg" }]],
  },
};

export default json;
