import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P38-E1",
    audio: "Audios/Page38/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='58' src='Audios/Page38/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page38/E1/1.jpg",
          audioUrl: "Audios/Page38/read.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E1/2.jpg",
          audioUrl: "Audios/Page38/suft.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E1/3.jpg",
          audioUrl: "Audios/Page38/play-with-a-ball.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E1/4.jpg",
          audioUrl: "Audios/Page38/go-on-a-boat.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E1/5.jpg",
          audioUrl: "Audios/Page38/snorkel.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E1/6.jpg",
          audioUrl: "Audios/Page38/collect-shells.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P38-E2",
    audio: "Audios/Page38/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='59' src='Audios/Page38/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page38/E1/1.jpg",
          audioUrl: "Audios/Page38/read.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E1/2.jpg",
          audioUrl: "Audios/Page38/suft.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E1/3.jpg",
          audioUrl: "Audios/Page38/play-with-a-ball.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E1/4.jpg",
          audioUrl: "Audios/Page38/go-on-a-boat.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E1/5.jpg",
          audioUrl: "Audios/Page38/snorkel.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E1/6.jpg",
          audioUrl: "Audios/Page38/collect-shells.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB5-2024-U5-P38-E3",
    audio: "Audios/Page38/audio-e3.mp3",
    video: "Videos/Unit 05.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='60' src='Audios/Page38/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page38/E3/1.jpg",
          audioUrl: "Audios/Page38/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E3/2.jpg",
          audioUrl: "Audios/Page38/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page38/E3/3.jpg",
          audioUrl: "Audios/Page38/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E3/4.jpg",
          audioUrl: "Audios/Page38/hinh4.mp3",
        },
      ],
    ],
  },
};

export default json;
