import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P21-E1",
    audio: "Audios/Page21/audio-e1.mp3",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page21/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and circle the correct words. <headphone name='29' src='Audios/Page21/tieude-e1.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          // color: "#00aeef",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: 5,
          border: "solid 3px",
          // borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "works / doesn’t_work",
          "takes_care_of / doesn’t_take_care_of", //2
          "monkeys / parrots", //3
          "12 / 20",
          "takes_care_of / doesn’t_take_care_of",
          "talks / doesn’t_talk",
        ],
        answers: ["0-0", "1-4", "2-0", "3-0", "4-0", "5-0"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:50px'>
        <b>1</b> Nam <input id='0' type='Circle' /> in the zoo.<br>
        <b>2</b> Nam <input id='1' type='Circle' /> the big animals.<br>
        <b>3</b> His favorite animals are the <input id='2' type='Circle' />.<br>
        <b>4</b> There are <input id='3' type='Circle' /> monkeys at the zoo.<br>
        <b>5</b> He <input id='4' type='Circle' /> the parrots.<br>
        <b>6</b> Fred <input id='5' type='Circle' /> to visitors.<br>
      </div>
      `,
    },
  },

  2: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P21-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title: "Ask and answer.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page21/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P21-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3 ",
        title: "Talk about a family member’s job.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page21/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P21-E4",
    audio: "",
    video: "",
    hideBtnFooter:true,

    // exerciseKey: "img/FriendsPlus/Page21/E4/Key/answerKey.png",
    component: D1,
    // maxLength: ,
    titleQuestion: [
      {
        num: "4",
        title:
          "Write about a family member’s job. Use the example to help you. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page21/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page21/E4/2.jpg" },
        { url: "img/FriendsPlus/Page21/E4/3.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page21/E4/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page21/E4/5.jpg" },
        { url: "img/FriendsPlus/Page21/E4/6.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page21/E4/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page21/E4/8.jpg" },
        { url: "img/FriendsPlus/Page21/E4/9.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page21/E4/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page21/E4/11.jpg" }],
      [
        { url: "img/FriendsPlus/Page21/E4/12.jpg" },
        { url: "img/FriendsPlus/Page21/E4/13.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page21/E4/14.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page21/E4/15.jpg" }],
    ],
  },
};

export default json;
