import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P24-E1",
    audio: "Audios/Page24/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='34' src='Audios/Page24/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page24/E1/1.jpg",
          audioUrl: "Audios/Page24/ten.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/3.jpg",
          audioUrl: "Audios/Page24/twenty.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/5.jpg",
          audioUrl: "Audios/Page24/twenty-one.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/7.jpg",
          audioUrl: "Audios/Page24/twenty-two.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/9.jpg",
          audioUrl: "Audios/Page24/twenty-three.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/11.jpg",
          audioUrl: "Audios/Page24/twenty-four.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/12.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/13.jpg",
          audioUrl: "Audios/Page24/twenty-five.mp3",
        },
      ],
      [{ url: "img/FriendsPlus/Page24/E1/14.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page24/E1/15.jpg",
          audioUrl: "Audios/Page24/twenty-six.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/16.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/17.jpg",
          audioUrl: "Audios/Page24/twenty-seven.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/18.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/19.jpg",
          audioUrl: "Audios/Page24/twenty-eight.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/20.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/21.jpg",
          audioUrl: "Audios/Page24/twenty-nine.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/22.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/23.jpg",
          audioUrl: "Audios/Page24/thirty.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/24.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/25.jpg",
          audioUrl: "Audios/Page24/forty.mp3",
        },
      ],
      [{ url: "img/FriendsPlus/Page24/E1/26.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page24/E1/27.jpg",
          audioUrl: "Audios/Page24/fifty.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/28.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/29.jpg",
          audioUrl: "Audios/Page24/sixty.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/30.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/31.jpg",
          audioUrl: "Audios/Page24/seventy.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/32.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/33.jpg",
          audioUrl: "Audios/Page24/eighty.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/34.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/35.jpg",
          audioUrl: "Audios/Page24/ninety.mp3",
        },
        { url: "img/FriendsPlus/Page24/E1/36.jpg" },
        {
          url: "img/FriendsPlus/Page24/E1/37.jpg",
          audioUrl: "Audios/Page24/one-hundred.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P24-E2",
    audio: "Audios/Page24/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and sing. <headphone name='35' src='Audios/Page24/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page24/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 3",
    id: "SB5-2024-U3-P24-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title: "Sing and do.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page24/E2/1.jpg" }]],
  },
};

export default json;
