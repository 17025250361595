import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P83-E1",
    audio: "Audios/Page82/audio-e3.mp3",
    video: "Videos/Unit 11.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='132' src='Audios/Page82/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page82/E3/1.jpg",
          audioUrl: "Audios/Page82/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E3/2.jpg",
          audioUrl: "Audios/Page82/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page82/E3/3.jpg",
          audioUrl: "Audios/Page82/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E3/4.jpg",
          audioUrl: "Audios/Page82/hinh4.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P83-E2",
    audio: "Audios/Page83/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='133' src='Audios/Page83/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page83/E2/1.jpg" },],[
        { url: "img/FriendsPlus/Page83/E2/2.jpg" },
        { url: "img/FriendsPlus/Page83/E2/3.jpg",audioUrl: "Audios/Page83/cau1.mp3" },
        { url: "img/FriendsPlus/Page83/E2/4.jpg" },],[
        { url: "img/FriendsPlus/Page83/E2/5.jpg" },
        { url: "img/FriendsPlus/Page83/E2/6.jpg",audioUrl: "Audios/Page83/cau2.mp3" },
        { url: "img/FriendsPlus/Page83/E2/7.jpg",audioUrl: "Audios/Page83/cau3.mp3" },
        { url: "img/FriendsPlus/Page83/E2/8.jpg" },],[
        { url: "img/FriendsPlus/Page83/E2/9.jpg" },
        { url: "img/FriendsPlus/Page83/E2/10.jpg",audioUrl: "Audios/Page83/cau4.mp3" },
        { url: "img/FriendsPlus/Page83/E2/11.jpg" },],[
        { url: "img/FriendsPlus/Page83/E2/12.jpg" },
        { url: "img/FriendsPlus/Page83/E2/13.jpg",audioUrl: "Audios/Page83/cau5.mp3" },
        { url: "img/FriendsPlus/Page83/E2/14.jpg" },],[
        { url: "img/FriendsPlus/Page83/E2/15.jpg" },
        { url: "img/FriendsPlus/Page83/E2/16.jpg",audioUrl: "Audios/Page83/cau6.mp3" },
        { url: "img/FriendsPlus/Page83/E2/17.jpg" },],[
        { url: "img/FriendsPlus/Page83/E2/18.jpg" },
        { url: "img/FriendsPlus/Page83/E2/19.jpg",audioUrl: "Audios/Page83/cau7.mp3" },
        { url: "img/FriendsPlus/Page83/E2/20.jpg" },],[
        { url: "img/FriendsPlus/Page83/E2/21.jpg" },
    ]
    ],
  },
  3: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P83-E3",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "3",
        title:
          "Complete the sentences.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    exerciseKey: "img/FriendsPlus/Page83/E3/Key/answerKey.png",
    component: T6,
    maxLength: 8,
    textAlign: "center",

    inputSize: 130,

    // isHello: true,
    questions: [
      {
        title: `
          
            <div >
              <b>1.</b><img style='height:12mm' src='img/FriendsPlus/Page83/E3/1.jpg'/> Turn <u style='color:rgb(25 181 239)' >&ensp;left&ensp;</u> at the hospital.<br>
              <b>2.</b><img style='height:15mm' src='img/FriendsPlus/Page83/E3/2.jpg'/> Go#at the traffic lights.<br>
              <b>3.</b><img style='height:12mm' src='img/FriendsPlus/Page83/E3/3.jpg'/> Turn#at the school.<br>
              <b>4.</b><img style='height:13mm' src='img/FriendsPlus/Page83/E3/4.jpg'/> It’s#the library.<br>
              <b>5.</b><img style='height:9mm' src='img/FriendsPlus/Page83/E3/5.jpg'/> It’s#the park.
            </div>
                 
        `,
        answer: ["straight", "right", "opposite", "next to"],
      },
    ],
  },
  4: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P83-E4",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page83/E4/Key/answerKey.png",
    maxLength: 32,
    titleQuestion: [
      {
        num: "4",
        title:
          "Look at the picture below and write directions to the park.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    textAlign: "center",

    inputSize: 130,

    // isHello: true,
    questions: [
      {
        title: `
          
            <div >
              <b>1.</b>&ensp;Go<u style='color:rgb(25 181 239)' >&ensp;straight&ensp;</u> <br> 
              <b>2.</b>&ensp;Turn#at the traffic lights. <br>
              <b>3.</b>&ensp;Now you are#the school. <br>
              <b>4.</b>&ensp;Turn#and go straight. <br>
              <b>5.</b>&ensp;It’s#the river. <br>
            </div><img  src='img/FriendsPlus/Page83/E4/1.jpg'/>
          
        
        `,
        answer: ["left", "opposite", "right", "next to"],
      },
    ],
  },
  5: {
    unit: "Unit 11",
    id: "SB5-2024-U11-P83-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page83/E5/1.jpg" }]],
  },
};

export default json;
