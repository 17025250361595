import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
const json = {
  1: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P51-E1",
    audio: "Audios/21-audio.mp3",
    exerciseKey: "img/FriendsPlus/Page51/Key/E1answerKey.png",
    video: "",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and draw.<headphone name='21' src='Audios/21-tieude.mp3'></headphone> ",
        color: "#203c8f",
        left: -20,
      },
    ],
    stylesTextInput: { width: 540 },
    // textAlign:'center',
    component: Circle_Write,

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          // fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "Did you go to the zoo? ",
          "Did he like the movie? ",
          "Did they go on vacation?",
          "Did she go on foot?",
        ],
        answers: ["0-4", "1-4", "2-4", "3-4"],
        initialValue: ["0-4"],
      },
      Layout: `
      
      <div style='display:flex;line-height:47px'>
        <div>
          <b>1.</b>&ensp;<input id=0 type='Circle'/><br>
          <b>2.</b>&ensp;<input id=1 type='Circle'/><br>
        </div>
        <div style='margin-left:50px'>
          <b>3.</b>&ensp;<input id=2 type='Circle'/><br>
          <b>4.</b>&ensp;<input id=3 type='Circle'/><br>
        </div>
      </div>
      `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P51-E2",
    audio: "Audios/22-audio.mp3",
    video: "",

    exerciseKey: "img/FriendsPlus/Page51/Key/E2answerKey.png",
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "2",
        title:
          "Circle the correct word. Then listen and check. <headphone name='21' src='Audios/22-tieude.mp3'></headphone> ",
        color: "#203c8f",
        left: -50,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "go / see",
          "zebras / monkeys",
          "lions / giraffes",
          "kangaroos / crocodiles",
        ],
        answers: ["0-0", "1-0", "2-0", "3-4"],
        initialValue: [],
      },
      Layout: `
      
      <div style='display:flex'>
        <div>
          Did you <sup>1</sup><input id=0 type=Circle /> to the zoo? <br>
          Yes, I did.<br>
          Did you see the <sup>2</sup><input id=1 type=Circle />?<br>
          Yes, I did.<br>
          Did you see the <sup>3</sup><input id=2 type=Circle />?<br>
          Yes, I did.<br>
          Did you see the <sup>4</sup><input id=3 type=Circle />?<br>
          No, I didn’t.<br>
        </div>
        <img style='height:10cm' src='img/FriendsPlus/Page51/E2/1.jpg'/>
      </div>
      `,
    },
  },

  3: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P51-E3",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page51/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: "Look and match.",
        color: "#203c8f",
        // numberImg:"b",
        left: -23,
      },
    ],
    component: MatchDots,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "34px",
              left: "120px",
              width: "217px",
              height: "51px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "153px",
              left: "406px",
              width: "275px",
              height: "51px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "457px",
              left: "183px",
              width: "166px",
              height: "51px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "248px",
              left: "106px",
              width: "173px",
              height: "51px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "312px",
              left: "472px",
              width: "280px",
              height: "51px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "226px",
              left: "790px",
              width: "189px",
              height: "51px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
        ],
        answers: ["2-5", "1-4", "0-3"],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          // borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "school_trip / boat_trip.",
          "movie_theater / museum.",
          "train / bus.",
          "four / five.",
          " postcard / dinosaur_model.",
        ],
        answers: ["1-4", "3-4", "2-0", "0-0", "4-6"],
        initialValue: ["0-0"],
      },
      Layout: `
      
        <input id='0' type='boxMatch' />
        <input id='1' type='boxMatch' />
        <input id='2' type='boxMatch' />
        <input id='3' type='boxMatch' />
        <input id='4' type='boxMatch' />
        <input id='5' type='boxMatch' />
        <img style='height:13cm' src='img/FriendsPlus/Page51/E3/1.jpg'/>
      `,
    },
  },
  5: {
    unit: "Unit 7",
    id: "WB5-2024-U7-P51-E5",
    audio: "",
    video: "",

    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 700,
      paddingTop: 5,
    },
    inputSize: 720,
    titleQuestion: [
      {
        num: "5",
        title: "Would you like to visit a cave? Why / Why not?",
        color: "#203c8f",
        left: 80,
      },
    ],
    questionImage: [],
    isHello: true,
    questions: [
      {
        title: `
				<textarea id='0' rows=7 ></textarea>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
