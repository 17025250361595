import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P66-E1",
    audio: "Audios/Page66/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='105' src='Audios/Page66/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page66/E1/1.jpg",
          audioUrl: "Audios/Page66/get-up.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/2.jpg",
          audioUrl: "Audios/Page66/have-breakfast.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/3.jpg",
          audioUrl: "Audios/Page66/go-to-school.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/4.jpg",
          audioUrl: "Audios/Page66/go-home.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/5.jpg",
          audioUrl: "Audios/Page66/have-dinner.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/6.jpg",
          audioUrl: "Audios/Page66/go-to-bed.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P66-E2",
    audio: "Audios/Page66/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='106' src='Audios/Page66/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page66/E1/1.jpg",
          audioUrl: "Audios/Page66/get-up.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/2.jpg",
          audioUrl: "Audios/Page66/have-breakfast.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/3.jpg",
          audioUrl: "Audios/Page66/go-to-school.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/4.jpg",
          audioUrl: "Audios/Page66/go-home.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/5.jpg",
          audioUrl: "Audios/Page66/have-dinner.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E1/6.jpg",
          audioUrl: "Audios/Page66/go-to-bed.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P66-E3",
    audio: "Audios/Page66/audio-e3.mp3",
    video: "Videos/Unit 09.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='107' src='Audios/Page66/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page66/E3/1.jpg",
          audioUrl: "Audios/Page66/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E3/2.jpg",
          audioUrl: "Audios/Page66/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page66/E3/3.jpg",
          audioUrl: "Audios/Page66/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E3/4.jpg",
          audioUrl: "Audios/Page66/hinh4.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page66/E3/5.jpg",
          audioUrl: "Audios/Page66/hinh5.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E3/6.jpg",
          audioUrl: "Audios/Page66/hinh6.mp3",
        },
      ],
    ],
  },
};

export default json;
