import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P68-E1",
    audio: "Audios/Page68/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='109' src='Audios/Page68/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page68/E1/1.jpg",
          audioUrl: "Audios/Page68/two-fifteen.mp3",
        },
        { url: "img/FriendsPlus/Page68/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page68/E1/3.jpg",
          audioUrl: "Audios/Page68/two-thrity.mp3",
        },
        { url: "img/FriendsPlus/Page68/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page68/E1/5.jpg",
          audioUrl: "Audios/Page68/two-forty-five.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P79-E2",
    audio: "Audios/Page68/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and sing. <headphone name='110' src='Audios/Page68/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page68/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P79-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title: "Sing and do.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page68/E2/1.jpg" }]],
  },
  4: {
    unit: "Unit 9",
    id: "SB5-2024-U9-P79-E4",
    audio: "",
    component: T6,
    checkUppercase: true,
    maxLength: 20,
    textareaStyle: {
      width: 700,
    },
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 90,
    titleQuestion: [
      {
        num: "4",
        title:
          "Look at the picture. Write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <span style='color:rgb(25 181 239)'>Two boys are playing with a ball. A dog is swimming ...</span><br>
        <img  src='img/FriendsPlus/Page68/E2/1.jpg'/>
        <div>
          <textarea id='0' rows='2'></textarea id='0'>
        </div>


        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
