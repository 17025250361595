import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  5: {
    unit: "Review 1",
    id: "SB5-2024-RV1-P29-E5",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: {
      fontSize: "27px",
    },
    inputSize: 150,
    padding: 0,
    // textAlign: 'center',
    exerciseKey: "img/FriendsPlus/Page29/E5/Key/answerKey.png",
    // maxLength: 5,
    titleQuestion: [
      {
        num: "5",
        title:
          "Look and write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <img style='width:100%' src='img/FriendsPlus/Page29/E5/1.jpg'/>
            <span style='position: absolute; top: 246px; left: 474px'>#</span>
            <span style='position: absolute; top: 246px; left: 867px'>#</span>
            <span style='position: absolute; top: 537px; left: 102px'>#</span>
            <span style='position: absolute; top: 537px; left: 474px'>#</span>
            <span style='position: absolute; top: 537px; left: 867px'>#</span>
          </div>
        `,
        answer: ["chicken", "bubble tea", "pizza", "fries", "noodles"],
      },
    ],
  },
  6: {
    unit: "Review 1",
    id: "SB5-2024-RV1-P29-E6",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "6",
        title:
          "What does he / she like? Look and say. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page29/E6/1.jpg" }]],
  },
};

export default json;
