import React from "react";
import ReactHtmlParser from "react-html-parser";

export const DrawCircleArray = ({
  isHello,
  userAnswers,
  isDoing,
  listBoolean,
  circleColor,
  padding,
}) => {
  //['0-3', '1-4', '2-5']
  if (!userAnswers) return null;
  const calculatePosition = (rect) => {
    const { x, y, width, height } = rect;
    return {
      x: x + document.documentElement.scrollLeft,
      y: y + document.documentElement.scrollTop,
      width,
      height,
    };
  };
  return userAnswers.map((item, index) =>
    DrawCircle({ isHello, item, isDoing, listBoolean, circleColor, padding })
  );
};

export const DrawCircle = ({
  isHello,
  item,
  isDoing,
  listBoolean,
  circleColor,
  padding,
}) => {
  //['0-3', '1-4', '2-5']
  if (!item) return null;
  const calculatePosition = (rect) => {
    const { x, y, width, height } = rect;
    return {
      // x: x + document.documentElement.scrollLeft,
      // y: y + document.documentElement.scrollTop,
      x,
      y,
      width,
      height,
    };
  };
  const [first, second] = item.split("-").sort((a, b) => a - b);
  const [paddingY, paddingX] = padding?.split(" ") || [0, 0];
  if (!first || !second) return null;
  let color = circleColor || "gray";
  if (isDoing === false) {
    const isCorrect = listBoolean[`${first}-${second}`];
    if (isHello !== true) {
      color = isCorrect ? "green" : "red";
    } else {
      color = "green";
    }
  }
  const firstObject = document.getElementsByClassName(`dot-${first}`)[0];
  const secondObject = document.getElementsByClassName(`dot-${second}`)[0];
  const firstPosition = firstObject?.getBoundingClientRect();
  const secondPosition = secondObject?.getBoundingClientRect();
  if (!firstPosition || !secondPosition) return null;
  return (
    <div
      style={{
        position: "fixed",
        left: calculatePosition(firstPosition).x - paddingX,
        top: calculatePosition(firstPosition).y - paddingY,
        width:
          calculatePosition(secondPosition).x -
          calculatePosition(firstPosition).x +
          calculatePosition(firstPosition).width +
          paddingX * 2,
        height:
          calculatePosition(secondPosition).y -
          calculatePosition(firstPosition).y +
          calculatePosition(firstPosition).height +
          paddingY * 2,
        border: `2px solid ${color}`,
        borderRadius: "50%",
        pointerEvents: "none",
      }}
    ></div>
  );
};

export function calculateResultLine(userAnswers, answers) {
  let userAnswer = userAnswers;
  if (userAnswers.length < answers.length) {
    userAnswer.push("");
  }

  const total = Math.max(userAnswers.length, answers.length); // tổng số đáp án hoặc tổng số đã chọn;
  const booleanArray = new Array(total);
  const listBoolean = {}; // biến hiển thị css đúng/sai
  userAnswer.forEach((item, index) => {
    const isCorrect = answers.includes(item);
    booleanArray[index] = isCorrect;
    Object.assign(listBoolean, { [item]: isCorrect });
  });

  // params
  const correct = booleanArray.reduce(
    (total, item) => (total += item ? 1 : 0),
    0
  );
  const percent = parseInt((correct * 100) / total); // tính %
  const resultString = `${correct}/${total}`; // điểm / tổng
  const star = percent / 20;
  return { listBoolean, booleanArray, percent, resultString, star };
}

function PointDotv2({ id, data, margin, setData, sort }) {
  const { boxMatch } = data;
  const marginTransformed =
    margin
      .split(" ")
      .map((item, index) => {
        if (index === 0) return Number(item);
        return Number(item) * 2;
      })
      .join("px ") + "px";
  console.log(marginTransformed);
  return (
    <div
      className={`dot-${id}`}
      style={{
        cursor: "pointer",
        // transform: "translate(-50%, -50%)",
        margin: marginTransformed,
        ...boxMatch[id].boxMatchStyle,
      }}
      // onClick={() => handleClick(id)}
    >
      {/* {dotSelect()} */}
      {ReactHtmlParser(boxMatch[id].children)}
    </div>
  );
}

export default PointDotv2;
