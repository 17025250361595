import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P17-E1",
    audio: "Audios/Page16/audio-e3.mp3",
    video: "Videos/Unit 02.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='21' src='Audios/Page16/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page16/E3/1.jpg",
          audioUrl: "Audios/Page16/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page16/E3/2.jpg",
          audioUrl: "Audios/Page16/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page16/E3/3.jpg",
          audioUrl: "Audios/Page16/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page16/E3/4.jpg",
          audioUrl: "Audios/Page16/hinh4.mp3",
        },
      ],
      [
        {
          audioUrl: "Audios/Page16/hinh5.mp3",
          url: "img/FriendsPlus/Page16/E3/5.jpg",
        },
        {
          audioUrl: "Audios/Page16/hinh6.mp3",
          url: "img/FriendsPlus/Page16/E3/6.jpg",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P17-E2",
    audio: "Audios/Page17/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='22' src='Audios/Page17/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page17/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page17/E2/2.jpg" },

        {
          url: "img/FriendsPlus/Page17/E2/3.jpg",
          audioUrl: "Audios/Page17/hinh1.mp3",
        },
        { url: "img/FriendsPlus/Page17/E2/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page17/E2/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page17/E2/6.jpg" },

        {
          url: "img/FriendsPlus/Page17/E2/7.jpg",
          audioUrl: "Audios/Page17/hinh2.mp3",
        },
        { url: "img/FriendsPlus/Page17/E2/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page17/E2/9.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page17/E2/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page17/E2/11.jpg",
          audioUrl: "Audios/Page17/hinh3.mp3",
        },

        { url: "img/FriendsPlus/Page17/E2/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page17/E2/13.jpg" }],
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P17-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Look and say. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page17/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P17-E4",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page17/E4/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    maxLength: 17,

    // textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 250,
    titleQuestion: [
      {
        num: "4",
        title:
          "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
				<img  src='img/FriendsPlus/Page17/E4/1.jpg'/>
        <div style=' line-height: 50px'>
						<b>1</b>&ensp;<u style='color:rgb(25 181 239)' >&ensp;She works&ensp;</u> in a hospital.<br>
						<b>2</b>&ensp;#	in an office.<br>
						<b>3</b>&ensp;#	in a fire station.<br>
						<b>4</b>&ensp;# in an airport.

				</div>
        
        `,
        answer: ["He doesn't work", "He works", "She doesn't work"],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P17-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page17/E5/1.jpg" }]],
  },
};

export default json;
