import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Starter-test2",
    id: "WB5-2024-Starter-P48-E1",
    exerciseKey: "img/FriendsPlus/Page1/Key/E1answerKey.png",
    audio: "",
    video: "",
    component: Circle_Write,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title: "Read and circle the words below. Then write.",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    question: {
      leftContent: 50,
      DrawLines: {
        widthHeighDot: 5,
        widthLineto: 3,
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "0px",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              border: "1px solid gray",
              background: "gray",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              border: "1px solid gray",
              background: "gray",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              border: "1px solid gray",
              background: "gray",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              border: "1px solid gray",
              background: "gray",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              border: "1px solid gray",
              background: "gray",
            },
          },
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        isHiddenCheck: true,
        inputStyle: { width: 150, paddingBottom: 10, textAlign: "center" },
        answers: [
          "bones",
          "dinosaurs",
          "alive",
          "dead",
          "roar",
          "scary",
          "models",
          "scream",
        ],
        initialValue: ["bones"],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <b>1.</b>&ensp;People and animals have these inside them. <input id=0></input><br>
        <b>2.</b>&ensp;These animals lived on Earth millions of years ago. <input id=1></input><br>
        <b>3.</b>&ensp;Everyone in your class is this. <input id=2></input><br>
        <b>4.</b>&ensp;All of the dinosaurs are this. <input id=3></input><br>
        <b>5.</b>&ensp;A loud noise that some animals make. <input id=4></input><br>
        <b>6.</b>&ensp;Something that makes you feel scared is this. <input id=5></input><br>
        <b>7.</b>&ensp;People make these to show you what something looks like. <input id=6></input><br>
        <b>8.</b>&ensp;You make this noise when you are scared.<input id=7></input><br>
       
         <img style='height:10cm' src='img/FriendsPlus/Page1/E1/1.jpg'/>
        `,
    },
  },
  2: {
    unit: "Starter",
    id: "WB5-2024-Starter-P48-E2",
    audio: "Audios/19-audio.mp3",
    video: "",

    exerciseKey: "img/FriendsPlus/Page1/Key/E2answerKey.png",
    component: T6,
    // maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 150,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen. Write the word from 1. <headphone name='19' src='Audios/19-tieude.mp3'></headphone>",
        color: "#203c8f",
        left: 50,
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
				<div style='display:flex'>
          <div style='width:15cm'>
            <b>The school trip</b><br>
            <b>1.</b>&ensp;Where did Mark go? The#dinosaur museum.<br>
            <b>2.</b>&ensp;There were lots of#of dinosaurs.<br>
            <b>3.</b>&ensp;The dinosaurs’#were interesting.<br>
            <b>4.</b>&ensp;It was fun, but they looked#.<br>
            <b>5.</b>&ensp;He learned that the last dinosaurs were#65 million years ago.<br>
          </div>
            <img style='height:10cm' src='img/FriendsPlus/Page1/E2/1.jpg'/>
        </div>
        
        `,
        answer: ["dinosaur", "models", "roars", "scary", "alive"],
      },
    ],
  },
  3: {
    unit: "Starter",
    id: "WB5-2024-Starter-P48-E3",
    audio: "Audios/Page48/Track 085.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='85' src='Audios/Page48/Track 085 tieu-de.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    styleContent: {
      width: "90%",
      marginLeft: 50,
    },
    questionImage: [[{ url: "img/FriendsPlus/Page8/E3/1.jpg" }]],
  },
  4: {
    unit: "Starter",
    id: "WB5-2024-Starter-P48-E4",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page8/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: "Read again and circle the correct answer. ",
        color: "#203c8f",
        numberImg: "b",
        left: 0,
      },
    ],
    component: Circle_Write,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
        ],
        answers: [],
        initialValue: [],
      },
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "1px 4px",
          border: "2px solid",

          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: "1px 4px",
          borderRadius: "50%",
          color: "black",
          border: "2px solid",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.|",
          "a.| <br> b.| <br> c.| ",
          "a.| <br> b.| <br> c.|",
        ],
        answers: ["0-6", "1-12", "4-0", "2-6", "3-0"],
        initialValue: [],
      },
      Layout: `
      <img style='height:10cm' src='img/FriendsPlus/Page8/E3/1.jpg'/>
     <div style='display:flex'>
      <div style='margin-right:50px;'>
       <div><b>1.</b> Mr. Khanh was____________.
        <div style='display:flex'>
          <div><input id='0' type='Circle'/></div>
          <div>a scientist <br> a farmer <br> a tourist</div>
        </div>
       </div>
       <div style='width:10cm'><b>3.</b> When Mr. Khanh found the cave he was____________.
        <div style='display:flex'>
          <div><input id='4' type='Circle'/></div>
          <div>2023 <br> 2009 <br> 1991</div>
        </div>
       </div>
      </div> 
       
      <div> 
       <div style='width:10cm'><b>2.</b> Mr. Khanh first found the cave in____________.
        <div style='display:flex'>
          <div><input id='1' type='Circle'/></div>
          <div>suprised <br> sad <br> scared</div>
        </div>
       </div>
       <div><b>4.</b> The cave is very____________.
        <div style='display:flex'>
          <div><input id='2' type='Circle'/></div>
          <div>old <br> big <br> small</div>
        </div>
       </div>
      </div> 
    </div> 
       <div><b>5.</b> Inside the cave there is____________.
        <div style='display:flex'>
          <div><input id='3' type='Circle'/></div>
          <div>a jungle <br> a farm <br> a museum</div>
        </div>
       </div>
      `,
    },
  },
  5: {
    unit: "Starter",
    id: "WB5-2024-Starter-P48-E5",
    audio: "",
    video: "",

    component: T6,
    // maxLength: 5,
    // textAlign: "center",
    textareaStyle: {
      width: 700,
      paddingTop: 5,
    },
    inputSize: 720,
    titleQuestion: [
      {
        num: "5",
        title: "Would you like to visit a cave? Why / Why not?",
        color: "#203c8f",
        left: 80,
      },
    ],
    questionImage: [],
    isHello: true,
    questions: [
      {
        title: `
				<textarea id='0' rows=7 ></textarea>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
