import React, { useCallback } from "react";
import ReactHtmlParser from "react-html-parser";

export function calculateResultCircle(
  userAnswers,
  answers,
  listWords,
  limitSelect
) {
  // chấm điểm theo từng từ id_word
  let booleanArray = [];
  let userAnswer = [];
  if (userAnswers.length > answers.length) {
    userAnswer = userAnswers;
  } else {
    answers.map((value, index) =>
      userAnswers.includes(value) ? userAnswer.push(value) : userAnswer.push("")
    );
  }
  // let correctWord = 0;
  // tổng điểm các từ

  let listBoolean = {}; // biến hiển thị css đúng/sai
  userAnswer.forEach((item, index) => {
    const isCorrect = answers.includes(item);
    booleanArray[index] = isCorrect;
    Object.assign(listBoolean, { [item]: isCorrect });
  });
  const correctAnswer = booleanArray.filter((a) => a !== false);
  // chấm điểm theo mỗi câu Id
  const booleanArrayById = listWords.map((item, index) => {
    const id_words = Object.keys(listBoolean).filter((x) =>
      x.includes(`${index}-`)
    ); // id các từ đã chọn
    if (!id_words[0]) return false; //không chọn => false
    const isCorrect = id_words.every((key) => listBoolean[key]); // chọn đúng tất cả => true
    return isCorrect;
  });

  // params
  // let correct = correctWord;
  // let total = Math.max(userAnswers.length, answers.length);
  // tổng số đáp án hoặc tổng số đã chọn;
  // chấm điểm theo câu
  // if (limitSelect) {
  //   correct = booleanArrayById.reduce(
  //     (total, item) => (total += item ? 1 : 0),
  //     0
  //   ); // phát hiện câu đúng cộng 1 điểm
  //   total = listWords.length; // tổng số câu
  //   booleanArray = booleanArrayById;
  // }
  const percent = parseInt((correctAnswer.length * 100) / answers.length); // tính %
  const resultString = `${correctAnswer.length}/${answers.length}`; // điểm / tổng
  const star = percent / 20;

  // answers=[] => ko chấm điểm
  if (!answers.length) {
    listBoolean = {};
    booleanArray = [];
  }

  return { listBoolean, booleanArray, percent, resultString, star };
}
//
function Circle({
  id,
  data,
  attribs,
  setData,
  ThSmile,
  dontChangeColor,
  isHello,
  UnderlineStyle,
  newSplit,
}) {
  const initialWordStyle = { cursor: "pointer", ...data.initialWordStyle };

  const { border, borderRadius } = data.selectWordStyle;
  const selectWordStyle = attribs.select
    ? { borderBottom: border, ...UnderlineStyle }
    : { ...initialWordStyle, ...data.selectWordStyle };
  let wordStyle = initialWordStyle;
  // console.log(attribs.type);

  const listWords = data.listWords[id];
  // console.log(listWords);
  const arrayWords = newSplit
    ? listWords
        .replace(/[\s,]+/g, " ")
        .trim()
        .split(" ")
    : listWords.split(/([\s|])/);
  // console.log(arrayWords);
  const handleClick = React.useCallback(
    (id_word) => {
      if (data.isDoing === false) return;
      let userAnswers = data.userAnswers ?? [];
      if (userAnswers.includes(id_word)) {
        userAnswers = userAnswers.filter((x) => x !== id_word); // bỏ chọn
      } else {
        userAnswers.push(id_word); // chọn thêm
      }
      //limitSelect
      const indexDelete = userAnswers.findIndex((x) => x.includes(`${id}-`));
      if (data.limitSelect) {
        const limitSelect = data.limitSelect;
        const [id, index] = id_word.split("-");
        const listSelected = userAnswers.filter((x) => x.includes(`${id}-`)); // đã chọn theo id hiện tại
        if (
          attribs.select !== "underlineText" &&
          listSelected.length > limitSelect
        ) {
          // vị trí cũ cần xóa
          userAnswers.splice(indexDelete, 1); //xóa 1 phần tử tại indexDelete
        }
        if (ThSmile === true) {
          userAnswers = listSelected;
        }
      }

      //update
      const newData = { ...data, userAnswers };
      setData(newData);
    },
    [data, setData]
  );

  const transform = useCallback((node, index) => {
    if (node.type === "tag" && node.name === "u") {
      return <u>{node.children[0].data}</u>;
    }
    if (node.type === "tag" && node.name === "br") {
      return <br />;
    }
    return <span>{node.data}</span>;
  }, []);

  if (!data) return null;
  return arrayWords.map((item, index) => {
    const id_word = `${id}-${index}`;
    const userAnswers = data.userAnswers ?? [];
    const isSelect = userAnswers.includes(id_word);
    //set style word
    if (isSelect) {
      wordStyle = selectWordStyle; // hiện khoanh tròn
      if (data.isDoing === false && data.answers.length) {
        // answers=[] => ko chấm điểm
        const isCorrect = data.resultCircle.listBoolean[id_word];
        let color = isCorrect ? "green" : "red"; //cái dòng này hắn mới set đổi màu cho đáp án nì anh

        if (dontChangeColor === true) {
          Object.assign(wordStyle, {
            // background: color,
            borderColor: color,
            color: "transparent",
          });
        } else {
          Object.assign(wordStyle, {
            background: attribs.select === "Tick" ? color : "none",
            borderColor: isHello === true ? "green" : color,
            color: isHello === true ? "green" : color,
          });
        }
      }
    } else {
      wordStyle = initialWordStyle; // ẩn khoanh tròn
    }
    //render
    const isWord = /^[\w✓✗"’(]/g; //  ký tự đầu chữ hoặc số hoặc ✓ hoặc " hoặc ’ hoặc (
    if (isWord.test(item)) {
      const word = item.replaceAll("_", " ");
      return (
        <span
          key={index}
          id={id_word}
          style={{ ...wordStyle }}
          onClick={() => handleClick(id_word)}
        >
          {/* {word} */}
          {ReactHtmlParser(word, { transform })}
        </span>
      );
    } else {
      if (item === "|") return null;
      return (
        <span key={index} id={id_word}>
          {ReactHtmlParser(item, { transform })}
        </span>
      );
    }
  });
}

export default Circle;
