import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P78-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Choose a pair. Ask and answer. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/> ",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page78/E1/1.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P78-E3",
    audio: "Audios/Page78/audio-e3.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and sing. <headphone name='124' src='Audios/Page78/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page78/E2/1.jpg" }]],
  },
  4: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P78-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "4",
        title: "Sing and do.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page78/E2/1.jpg" }]],
  },
  2: {
    unit: "Unit 10",
    id: "SB5-2024-U10-P78-E2",
    audio: "",
    component: T6,
    checkUppercase: true,
    maxLength: 20,
    hideBtnFooter:true,
    textareaStyle: {
      width: 700,
    },
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 90,
    titleQuestion: [
      {
        num: "2",
        title:
          "Write about a pair.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <span style='color:rgb(25 181 239)'>Alex and Lee don’t like playing chess, but they like reading comics. They ...</span><br>
        <div>
          <textarea id='0' rows='5'></textarea id='0'>
        </div>


        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
