import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P19-E1",
    audio: "Audios/Page19/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='25' src='Audios/Page19/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page19/E1/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page19/E1/2.jpg",
          audioUrl: "Audios/Page19/nest.mp3",
        },
        {
          url: "img/FriendsPlus/Page19/E1/3.jpg",
        },
        {
          url: "img/FriendsPlus/Page19/E1/4.jpg",
          audioUrl: "Audios/Page19/octopus.mp3",
        },
        {
          url: "img/FriendsPlus/Page19/E1/5.jpg",
        },
        {
          url: "img/FriendsPlus/Page19/E1/6.jpg",
          audioUrl: "Audios/Page19/parrot.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P19-E2",
    audio: "Audios/Page19/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and sing. <headphone name='26' src='Audios/Page19/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page19/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P19-E3",
    audio: "",
    video: "",
     
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page19/E3/Key/answerKey.png",
    component: T6,
    maxLength: 7,
    inputSize: 115,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Say the words with n, o, and p. Write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page19/E2/1.jpg'/>
        <div style='margin-left:60px'>#,#,#,#,#,#,#</div>
        
        `,
        answer: [
          "net / purple / plum / nest / orange / octopus / parrot",
          "net / purple / plum / nest / orange / octopus / parrot",
          "net / purple / plum / nest / orange / octopus / parrot",
          "net / purple / plum / nest / orange / octopus / parrot",
          "net / purple / plum / nest / orange / octopus / parrot",
          "net / purple / plum / nest / orange / octopus / parrot",
          "net / purple / plum / nest / orange / octopus / parrot",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P19-E4",
    audio: "Audios/Page19/audio-e4.mp3",
    video: "",
     
    exerciseKey: "img/FriendsPlus/Page19/E4/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    inputSize: 40,
    titleQuestion: [
      {
        num: "4",
        title:
          "Listen and complete the words. <headphone name='27' src='Audios/Page19/tieude-e4.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <div style='display:flex;'>
          <img style='height:47mm' src='img/FriendsPlus/Page19/E4/1.jpg'/>
            
            <div style='margin: 0px 30px;text-align:center'>
              <img  src='img/FriendsPlus/Page19/E4/2.jpg'/>
              <div>
                <u style='color:rgb(94 133 151)' >&ensp;o&ensp;</u>ctopus       
              </div>
            </div>
            <div  style='text-align:center'>
              <img  src='img/FriendsPlus/Page19/E4/3.jpg'/>
              <div>
                  #et     
              </div>
            </div>
        </div>
        <div style='display:flex'>
            <div style='text-align:center'>
              <img  src='img/FriendsPlus/Page19/E4/4.jpg'/>
              <div>
                #ur#le   
              </div>
            </div>
            <div style='margin: 0px 30px;text-align:center'>
              <img  src='img/FriendsPlus/Page19/E4/5.jpg'/>
              <div>
                  #ffice     
              </div>
            </div>
            <div  style='text-align:center'>
              <img  src='img/FriendsPlus/Page19/E4/6.jpg'/>
              <div>
                  #lum      
              </div>
            </div>
        </div>
        
        `,
        answer: ["n", "p", "p", "o", "p"],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB5-2024-U2-P19-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page19/E5/1.jpg" }]],
  },
};

export default json;
