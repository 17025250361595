import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

export const WordSpanString = (word) => {
  return `<span class='question-word-span'>${word}</span>`;
};

const json = {
  1: {
    unit: "Starter",
    id: "WB5-2024-Starter-P5-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page5/Key/E1answerKey.png",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Write the words in the correct order.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    stylesTextInput: { marginBottom: 10, marginLeft: 22, background: "none" },
    // textAlign: "center",
    inputSize: 600,
    styleHint: {
      // border: "none",
      // justifyContent: "center",
      paddingInline: 3,
      paddingBlock: "0px",
      // borderColor: "#2bbbf1",
      // color: "#57585a",
      styleElementHintBox: {
        background: "#2bbbf1",
        marginRight: 10,
        padding: "2px 5px",
        borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: ["like", "Vietnamese", "interesting", "I", "it’s", "because"],
      },
      {
        src: ["like", "I", "don’t", "math", "difficult", "because", "it’s"],
      },
      {
        src: [
          "art",
          "is",
          "painting",
          "Her",
          "because",
          "favorite",
          "she",
          "likes",
          "subject",
        ],
      },
      {
        src: [
          "is",
          "playing",
          "subject",
          "because",
          "His",
          "P.E.",
          "likes",
          "he",
          "basketball",
          "favorite",
        ],
      },
    ],
    // character: "",
    // hai , ba , bốn , năm

    questions: [
      {
        title: `
          <div style='display:flex'><b>1</b>&ensp;<div><hintbox id=0></hintbox></div></div>
           <div style='margin-left:24px;text-decoration:underline; color:gray'>I like Vietnamese because it’s interesting.&ensp;</div>
          <div style='display:flex'><b>2</b>&ensp;<div><hintbox id=1></hintbox></div></div>#
          <div style='display:flex'><b>3</b>&ensp;<div><hintbox id=2></hintbox></div></div>#
          <div style='display:flex'><b>4</b>&ensp;<div><hintbox id=3></hintbox></div></div>#
         

        `,
        answer: [
          "I don't like math because it's difficult.",
          "Her favorite subject is art because she likes painting.",
          "His favorite subject is P.E. because he likes playing basketball.|His favorite subject is PE because he likes playing basketball.",
        ],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "WB5-2024-Starter-P5-E2",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page5/Key/E2answerKey.jpg",
    // maxLength: 5,
    stylesTextInput: { background: "none" },
    textAlign: "center",
    inputSize: 150,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the questions and answers.",
        color: "#2d408e",
        left: 50,
        // prefix:[{text:"hello",icon:"fas fa-pen"}]
      },
    ],
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddingInline: 5,
      paddingBlock: "0px",
      borderColor: "#2bbbf1",
      color: "#57585a",
      styleElementHintBox: {
        // background: "#2bbbf1",
        marginRight: 20,
        padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: [
          "<span class='strikediag'>isn’t</span>",
          "is",
          "fishing",
          "playing",
          "he",
        ],
        width: "15cm",
      },
    ],
    // character: "",
    // hai , ba , bốn , năm
    component: T6,
    questions: [
      {
        title: `
          <hintbox id=0></hintbox>
          <div style='display:flex'>
                <div style='width:15cm'>
                      <div style='display:flex'>
                            <b>1</b>.
                            <div style='margin-left:15px'>Is Nguyen swimming? No, she <u style='color:gray; text-underline-offset: 5px'>&nbspisn’t&nbsp</u> . She’s reading.</div>
                      </div>
                      <div style='display:flex'>
                            <b>2</b>.
                            <div style='margin-left:15px'>Is Mai painting? Yes, she #.</div>
                      </div>
                      <div style='display:flex'>
                            <b>3</b>.
                            <div style='margin-left:15px'>Is Hung # basketball? Yes, he is.</div>
                      </div>
                      <div style='display:flex'>
                            <b>4</b>.
                            <div style='margin-left:15px'>Is Hai reading? No, # isn’t.</div>
                      </div>
                      <div style='display:flex'>
                            <b>5</b>.
                            <div style='margin-left:15px'>Is Bao #? No, he isn’t. He’s running.</div>
                      </div>
                </div>
            <img style='height:10cm' src='img/FriendsPlus/Page5/E2/1.jpg'/>


          </div>
         

        `,
        answer: ["is", "playing", "he", "fishing"],
      },
    ],
  },

  3: {
    unit: "Starter",
    id: "WB5-2024-Starter-P5-E3",
    audio: "",
    video: "",

    exerciseKey: "img/FriendsPlus/Page5/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: "Write about you.",
        color: "#2d408e",
        // numberImg: "b",
        left: 50,
      },
    ],
    styleHint: {
      border: "2px solid",
      justifyContent: "center",
      paddingInline: 3,
      paddingBlock: "0px",
      borderColor: "#2bbbf1",
      color: "#57585a",
      styleElementHintBox: {
        // background: "#2bbbf1",
        marginRight: 20,
        padding: "2px 5px",
        // borderRadius: "5px",
      },
    },
    hintBox: [
      {
        src: ["English", "Vietnamese", "math", "P.E.", "art"],
        width: "15cm",
      },
    ],
    stylesTextInput: { width: 170 },
    textAlign: "center",
    component: T6,
    questions: [
      {
        title: `
        <hintbox id=0></hintbox>
        <div style='display:flex; flex-direction: column'>
          <span><b>1.</b> I like # because I like #.</span>
          <span><b>2.</b> I don’t like # .</span>
          <span><b>3.</b> My favorite subject is # .</span>
        </div>
        `,
        answer: [
          "English|Vietnamese|math|P.E.|art",
          "English|Vietnamese|math|P.E.|art",
          "English|Vietnamese|math|P.E.|art",
          "English|Vietnamese|math|P.E.|art",
        ],
      },
    ],
  },
};

export default json;
