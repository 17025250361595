import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P91-E1",
    audio: "Audios/Page91/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='147' src='Audios/Page91/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page91/E1/1.jpg",
          audioUrl: "Audios/Page91/eat.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/2.jpg",
          audioUrl: "Audios/Page91/dream.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/3.jpg",
          audioUrl: "Audios/Page91/queen.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/4.jpg",
          audioUrl: "Audios/Page91/green.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/5.jpg",
          audioUrl: "Audios/Page91/sleep.mp3",
        },
        {
          url: "img/FriendsPlus/Page91/E1/6.jpg",
          audioUrl: "Audios/Page91/spoon.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P91-E2",
    audio: "Audios/Page91/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='148' src='Audios/Page91/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page91/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P91-E3",
    audio: "",
    video: "",
     
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page91/E3/Key/answerKey.png",
    component: T6,
    maxLength: 9,
    inputSize: 105,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Say the words with ea, ee, sl and sp. Write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // isHello: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page91/E2/1.jpg'/>
        <div style='text-align:center'>#,#,#,#,#,#,#,#</div>
        
        `,
        answer: [
          "sleep",
          "dream",
          "green",
          "spoon",
          "eat",
          "ice",
          "cream",
          "queen",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P91-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page91/E4/Key/answerKey.png",

    titleQuestion: [
      {
        num: "4",
        title:
          "Circle the missing letters. Write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    component: Circle_Write,
    question: {
      Write: {
        inputStyle: {
          width: 50,
          color: "black",
          textAlign: "center",
          maxLength: 2,
        },
        answers: ["sl", "ea", "sp", "ee"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          borderRadius: "50%",
          borderColor: "#00aeef",
          fontWeight: "bold",
        },
        selectWordStyle: {
          fontWeight: "bold",
          padding: 5,
          border: "solid 3px",
        },
        limitSelect: 1,
        listWords: [
          "ea ee sp sl", //0
          "ea ee sp sl", //1
          "ea ee sp sl", //2
          "ea ee sp sl", //3
        ],
        answers: ["2-4", "3-2", "1-0", "0-6"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
            <div style='margin-right:200px'>
              <img src='img/FriendsPlus/Page91/E4/1.jpg'>
              <div style='text-align:center'>
                <input id='0'  type='Circle' /> <br>
                <input id='0'/>eep
              </div>
            </div>


            <div>
              <img src='img/FriendsPlus/Page91/E4/2.jpg'>
              <div style='text-align:center'>
                <input id='1'  type='Circle' /> <br>
                <input id='1'/>t
              </div>
            </div>
      </div>


      <div style='display:flex'>
            <div style='margin-right:200px'>
              <img src='img/FriendsPlus/Page91/E4/3.jpg'>
              <div style='text-align:center'>
                <input id='2'  type='Circle' /> <br>
                <input id='2'/>oon
              </div>
            </div>


            <div>
              <img src='img/FriendsPlus/Page91/E4/4.jpg'>
              <div style='text-align:center'>
                <input id='3'  type='Circle' /> <br>
                gr<input id='3'/>n
              </div>
            </div>
      </div>
      `,
    },
  },
  5: {
    unit: "Unit 12",
    id: "SB5-2024-U12-P91-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page91/E5/1.jpg" }]],
  },
};

export default json;
