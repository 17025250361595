import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Review 1",
    id: "SB5-2024-RV1-P28-E1",
    audio: "Audios/Page28/audio-e1.mp3",
    video: "",
    component: T6,
    stylesTextInput: {
      fontSize: "24px",
    },
    inputSize: 50,
    padding: 0,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page28/E1/Key/answerKey.png",
    maxLength: 2,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write. <headphone name='41' src='Audios/Page28/tieude-e1.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex'>
            <div style='margin-left:40px' ><b>1.</b>&ensp;<u style='color: rgb(53, 173, 238);'>&ensp;35&ensp;</u></div>
            <div style='margin-left:40px' ><b>2.</b>#</div>
            <div style='margin-left:40px' ><b>3.</b>#</div>
            <div style='margin-left:40px' ><b>4.</b>#</div>
            <div style='margin-left:40px' ><b>5.</b>#</div>
            <div style='margin-left:40px' ><b>6.</b>#</div>
          </div>
        `,
        answer: ["22", "67", "53", "46", "99"],
      },
    ],
  },
  2: {
    unit: "Review 1",
    id: "SB4-RV1-P28-E2",
    audio: "Audios/Page28/audio-e2.mp3",
    video: "",
    component: DesignUnderLine,
    // recorder: true,
    question: [],
    exerciseKey: "img/FriendsPlus/Page28/E2/Key/answerKey.png",
    totalInput: 3,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and check (✓) the box. <headphone name='42' src='Audios/Page28/tieude-e2.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page28/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page28/E2/2.jpg" },
        { url: "img/FriendsPlus/Page28/E2/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page28/E2/4.jpg" },
        { url: "img/FriendsPlus/Page28/E2/5.jpg", input: 1 },
        { url: "img/FriendsPlus/Page28/E2/6.jpg" },
        { url: "img/FriendsPlus/Page28/E2/7.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page28/E2/8.jpg" }],
      [
        { url: "img/FriendsPlus/Page28/E2/9.jpg" },
        { url: "img/FriendsPlus/Page28/E2/10.jpg", input: 2 },
        { url: "img/FriendsPlus/Page28/E2/11.jpg" },
        { url: "img/FriendsPlus/Page28/E2/12.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page28/E2/13.jpg" },
        { url: "img/FriendsPlus/Page28/E2/14.jpg", input: 2 },
        { url: "img/FriendsPlus/Page28/E2/15.jpg" },
        { url: "img/FriendsPlus/Page28/E2/16.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page28/E2/17.jpg" },
        { url: "img/FriendsPlus/Page28/E2/18.jpg", input: 3 },
        { url: "img/FriendsPlus/Page28/E2/19.jpg" },
        { url: "img/FriendsPlus/Page28/E2/20.jpg", input: 3 },
      ],
    ],
  },
  3: {
    unit: "Review 1",
    id: "SB5-2024-RV1-P28-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page28/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Read. Circle the correct answer. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          // fontWeight: 600,
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "3px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          "Yes,_he_is. / No_,_he_isn't.",
          `Yes,_she_does. / No,_she_doesn't.`,
          `Yes,_they_are. / No,_they_aren't.`,
          `Yes,_they_do. / No,_they_don't.`,
        ],
        answers: ["0-0", "1-4", "2-0", "3-4"],
        initialValue: ["0-0"],
      },
      Layout: /*html*/ `
      <img src='img/FriendsPlus/Page28/E3/1.jpg' width=100%>
			<div style='display:flex;line-height:55px'>	
				<div>	
					1. Is Uyen’s dad a police officer?<br>
					2. Does Uyen’s mom work in a police station?<br>
					3. Are Uyen and her brother students?<br>
					4. Do they study every day?
				</div>	
				<div style='margin-left:50px'>
					<input id='0'  type='Circle' /><br>
					<input id='1'  type='Circle' /><br>
					<input id='2'  type='Circle' /><br>
					<input id='3'  type='Circle' />

				</div>	
			</div>	
      
    `,
    },
  },
  4: {
    unit: "Review 1",
    id: "SB5-2024-RV1-P28-E4",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: {
      fontSize: "24px",
    },
    inputSize: 40,
    padding: 0,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page28/E4/Key/answerKey.png",
    maxLength: 1,
    titleQuestion: [
      {
        num: "4",
        title:
          "Read again and write <i>T</i> (true) or <i>F</i> (false). <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex; gap: 20px; flex-direction: column;'>
            <div style='display: flex; gap: 30px;'>
              <div style='display: flex; gap: 10px; width: 400px;'>
                <span>1.</span>
                <span>Uyen’s dad is a police officer.</span>
              </div>
              <div style='height: 30px; width: 30px; font-size: 24px; font-weight: 500; text-align: center; border-width: 0px 0px 2px; border-style: solid; border-color: rgb(168, 168, 168); border-image: initial; border-radius: 0px; padding: 0px; display: inline; background-color: white; box-shadow: none; color: black;'>T</div>
            </div>
            <div style='display: flex; gap: 30px;'>
              <div style='display: flex; gap: 10px; width: 400px;'>
                <span>2.</span>
                <span>Uyen’s dad works in a fire station.</span>
              </div>
              #
            </div>
            <div style='display: flex; gap: 30px;'>
              <div style='display: flex; gap: 10px; width: 400px;'>
                <span>3.</span>
                <span>Uyen’s uncle is a pilot.</span>
              </div>
              #
            </div>
            <div style='display: flex; gap: 30px;'>
              <div style='display: flex; gap: 10px; width: 400px;'>
                <span>4.</span>
                <span>Uyen’s mother teaches students.</span>
              </div>
              #
            </div>
            <div style='display: flex; gap: 30px;'>
              <div style='display: flex; gap: 10px; width: 400px;'>
                <span>5.</span>
                <span>Minh and Uyen study at school.</span>
              </div>
              #
            </div>
          </div>
        `,
        answer: ["F", "F", "T", "T"],
      },
    ],
  },
};

export default json;
